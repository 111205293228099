import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function Title(props) {
    return (
        <Typography variant="text-xl" weight="semibold">
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
