import React from "react";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// https://mui.com/material-ui/react-snackbar/

const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />;
});
Alert.displayName = "Alert";

export default function SnackbarAlert() {
    const alerts = useSelector((state) => state.alert);
    const [, setOpen] = React.useState();
    // const dispatch = useDispatch();

    const handleClose = () => {
        // if (reason === 'clickaway') {
        // 	return;
        // }
        // dispatch({ type: 'REMOVE_ALERT', payload: id });
        setOpen(false);
    };
    return (
        <React.Fragment>
            {alerts &&
                alerts.length > 0 &&
                alerts.map((alert) => (
                    <Snackbar open={true} autoHideDuration={6000} onClose={handleClose} key={alert.id}>
                        <Alert onClose={handleClose} severity={alert.alertType} key={alert.id}>
                            {alert.msg}
                        </Alert>
                    </Snackbar>
                ))}
        </React.Fragment>
    );
}
