import { createSlice } from "@reduxjs/toolkit";
import { eventsApi } from "./eventsApi";

// Events slice
const eventsSlice = createSlice({
    name: "events",
    initialState: {
        list: [],
        selectedEvent: null,
        reminders: [],
        loading: false,
        error: null,
    },
    reducers: {
        selectEvent: (state, action) => {
            // Set selectedEvent based on action.payload and by finding the matching event in state.events
            state.selectedEvent = action.payload ? state.list.find((event) => event.id === action.payload.id) : null;
            state.reminders = action.payload ? action.payload.reminders : [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(eventsApi.endpoints.getAllEvents.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.getAllEvents.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(eventsApi.endpoints.getAllEvents.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.getEvents.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.getEvents.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(eventsApi.endpoints.getEvents.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.getEventReminders.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.getEventReminders.matchFulfilled, (state, action) => {
                state.loading = false;
                state.reminders = action.payload;
            })
            .addMatcher(eventsApi.endpoints.getEventReminders.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.createEvent.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.createEvent.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list.push(action.payload);
            })
            .addMatcher(eventsApi.endpoints.createEvent.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.updateEvent.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.updateEvent.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = state.list.map((event) => {
                    if (event.id === action.payload.id) {
                        return action.payload;
                    }
                    return event;
                });
            })
            .addMatcher(eventsApi.endpoints.updateEvent.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.deleteEvent.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.deleteEvent.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = state.list.filter((event) => event.id !== action.meta.arg.eventId);
            })
            .addMatcher(eventsApi.endpoints.deleteEvent.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.createEventReminder.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.createEventReminder.matchFulfilled, (state, action) => {
                state.loading = false;
                state.reminders ? state.reminders.push(action.payload) : (state.reminders = [action.payload]);
            })
            .addMatcher(eventsApi.endpoints.createEventReminder.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.updateEventReminder.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.updateEventReminder.matchFulfilled, (state, action) => {
                state.loading = false;
                if (state.selectedEvent?.id === action.payload.event_id) {
                    state.reminders = state.reminders.map((reminder) => {
                        if (reminder.id === action.payload.id) {
                            return action.payload;
                        }
                        return reminder;
                    });
                }
            })
            .addMatcher(eventsApi.endpoints.updateEventReminder.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(eventsApi.endpoints.deleteEventReminder.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(eventsApi.endpoints.deleteEventReminder.matchFulfilled, (state, action) => {
                state.loading = false;
                state.reminders = state.reminders.filter((reminder) => reminder.id !== action.meta.arg.reminderId);
            })
            .addMatcher(eventsApi.endpoints.deleteEventReminder.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default eventsSlice.reducer;

export const { selectEvent } = eventsSlice.actions;
