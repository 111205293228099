import produce from "immer";
import {
    CREATE_LIST_ERROR,
    CREATE_LIST_REQUEST,
    CREATE_LIST_SUCCESS,
    GET_LIST_SIMPLE_ERROR,
    GET_LIST_SIMPLE_REQUEST,
    GET_LIST_SIMPLE_SUCCESS,
    LOGOUT,
    UPDATE_LIST_ERROR,
    UPDATE_LIST_REQUEST,
    UPDATE_LIST_SUCCESS,
} from "../actions/types";

const initialState = {
    error: false,
    listCreated: "",
    listNameRequested: "",
    lists: [],
    simpleLists: null,
    loading: false,
    message: "",
    pagination: {},
    selectedList: {},
};

const listReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case CREATE_LIST_ERROR:
                draft.error = true;
                draft.loading = false;
                draft.message = payload;
                return draft;
            case CREATE_LIST_REQUEST:
                draft.error = false;
                draft.listNameRequested = payload;
                draft.loading = true;
                return draft;
            case CREATE_LIST_SUCCESS:
                draft.error = false;
                draft.listCreated = payload.list.name;
                draft.lists.unshift(payload.list);
                draft.simpleLists = draft.simpleLists ? [payload.list, ...draft.simpleLists] : [payload.list];
                draft.loading = false;
                draft.message = payload.message;
                return draft;
            case GET_LIST_SIMPLE_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case GET_LIST_SIMPLE_ERROR:
                draft.error = true;
                draft.lists = [];
                draft.loading = false;
                draft.message = payload;
                return draft;
            case GET_LIST_SIMPLE_SUCCESS:
                draft.error = false;
                draft.simpleLists = payload || [];
                draft.loading = false;
                return draft;
            case UPDATE_LIST_ERROR:
                return draft;
            case UPDATE_LIST_REQUEST:
                return draft;
            case UPDATE_LIST_SUCCESS:
                draft.lists.splice(
                    draft.lists.findIndex((list) => list.id === parseInt(payload.list.id)),
                    1,
                    payload.list
                );
                return draft;
            default:
                return draft;
        }
    });
};

export default listReducer;
