import produce from 'immer';
import {
    CREATE_BILLING_ERROR,
    CREATE_BILLING_REQUEST,
    CREATE_BILLING_SUCCESS,
    GET_BILLING_ERROR,
    GET_BILLING_REQUEST,
    GET_BILLING_SUCCESS,
    LOGOUT,
} from '../actions/types';

const initialState = {
    currentPeriodEnd: null,
    loading: false,
    segments: null,
};

const billingReducer = (state = initialState, action) => {
    const { type, payload } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case CREATE_BILLING_ERROR:
                draft.loading = false;
                return draft;
            case CREATE_BILLING_REQUEST:
                draft.loading = true;
                return draft;
            case CREATE_BILLING_SUCCESS:
                draft.loading = false;
                return draft;
            case GET_BILLING_REQUEST:
                draft.loading = true;
                return draft;
            case GET_BILLING_SUCCESS:
                draft.currentPeriodEnd = payload.stripe_current_period_end;
                draft.loading = false;
                draft.segments = payload.segments;
                return draft;
            case GET_BILLING_ERROR:
                draft.loading = false;
                return draft;
            default:
                return draft;
        }
    });
};

export default billingReducer;
