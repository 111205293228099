import { emptySplitApi as API } from "../api";

export const accountIntegrationsApi = API.injectEndpoints({
    endpoints: (builder) => ({
        getAccountIntegration: builder.query({
            query: ({ accountIntegrationId }) => ({
                url: `/account-integrations/${accountIntegrationId}`,
            }),
            transformResponse: (response) => response.accountIntegration,
            providesTags: (result) => [{ type: "AccountIntegrations", id: result?.id }],
            keepUnusedDataFor: 0,
        }),
        getAllAccountIntegrations: builder.query({
            query: () => ({
                url: `/account-integrations`,
            }),
            transformResponse: (response) => response.accountIntegrations,
            invalidatesTags: ["EventTypes"],
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "AccountIntegrations", id })),
                          { type: "AccountIntegrations", id: "LIST" },
                      ]
                    : ["AccountIntegrations"],
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useGetAccountIntegrationQuery, useGetAllAccountIntegrationsQuery } = accountIntegrationsApi;
