// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./reducers/account";
import accountIntegrationsReducer from "./app/services/accountIntegrations/accountIntegrationsSlice";
import accountIntegrationReducer from "./reducers/accountIntegration";
import accountPhonesReducer from "./reducers/accountPhones";
import alertReducer from "./reducers/alert";
import appReducer from "./reducers/app";
import authReducer from "./reducers/auth";
import automationsReducer from "./reducers/automations";
import backstageReducer from "./reducers/backstage";
import billingReducer from "./reducers/billing";
import broadcastReducer from "./app/services/broadcasts/broadcastsSlice";
import contactReducer from "./reducers/contact";
import conversationReducer from "./reducers/conversation";
import eventsReducer from "./app/services/events/eventsSlice";
import reminderTemplatesReducer from "./app/services/reminderTemplates/reminderTemplatesSlice";
import fieldsReducer from "./reducers/fields";
import integrationReducer from "./reducers/integration";
import keywordsReducer from "./reducers/keywords";
import listReducer from "./reducers/list";
import messageReducer from "./reducers/message";
import segmentsReducer from "./reducers/segments";
import { emptySplitApi as API } from "./app/services/api";
// const initialState = {};

// const middleware = [thunk];
// const composeEnhancers = composeWithDevTools({ trace: process.env.NODE_ENV === "development", traceLimit: 20 });
// const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
// export default store;

const store = configureStore({
    reducer: {
        account: accountReducer,
        accountIntegration: accountIntegrationReducer,
        accountIntegrations: accountIntegrationsReducer,
        accountPhones: accountPhonesReducer,
        alert: alertReducer,
        app: appReducer,
        auth: authReducer,
        automations: automationsReducer,
        backstage: backstageReducer,
        billing: billingReducer,
        broadcasts: broadcastReducer,
        contact: contactReducer,
        conversation: conversationReducer,
        events: eventsReducer,
        reminderTemplates: reminderTemplatesReducer,
        fields: fieldsReducer,
        integration: integrationReducer,
        keywords: keywordsReducer,
        list: listReducer,
        message: messageReducer,
        segments: segmentsReducer,
        [API.reducerPath]: API.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(API.middleware),
});
export default store;
