import { createSlice } from "@reduxjs/toolkit";
import { reminderTemplatesApi } from "./reminderTemplatesApi";

// Events slice
const reminderTemplatesSlice = createSlice({
    name: "reminderTemplates",
    initialState: {
        list: [],
        selectedTemplate: null,
    },
    reducers: {
        selectReminderTemplate: (state, action) => {
            // Set selectedEvent based on action.payload and by finding the matching event in state.events
            state.selectedTemplate = action.payload
                ? state.list.find((template) => template.id === action.payload.id)
                : null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(reminderTemplatesApi.endpoints.getAllReminderTemplates.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getAllReminderTemplates.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getAllReminderTemplates.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplates.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplates.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplates.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplate.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplate.matchFulfilled, (state, action) => {
                state.loading = false;
                state.reminders = action.payload;
            })
            .addMatcher(reminderTemplatesApi.endpoints.getReminderTemplate.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(reminderTemplatesApi.endpoints.createReminderTemplate.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.createReminderTemplate.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list.push(action.payload);
            })
            .addMatcher(reminderTemplatesApi.endpoints.createReminderTemplate.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(reminderTemplatesApi.endpoints.updateReminderTemplate.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.updateReminderTemplate.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = state.list.map((template) => {
                    if (template.id === action.payload.id) {
                        return action.payload;
                    }
                    return template;
                });
            })
            .addMatcher(reminderTemplatesApi.endpoints.updateReminderTemplate.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addMatcher(reminderTemplatesApi.endpoints.deleteReminderTemplate.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(reminderTemplatesApi.endpoints.deleteReminderTemplate.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = state.list.filter((template) => template.id !== action.meta.arg.templateId);
            })
            .addMatcher(reminderTemplatesApi.endpoints.deleteReminderTemplate.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default reminderTemplatesSlice.reducer;

export const { selectReminderTemplate } = reminderTemplatesSlice.actions;
