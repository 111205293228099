import produce from 'immer';
import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

const initialState = [];

const alertReducer = (state = initialState, action) => {
    const { type, payload } = action;
    return produce(state, (draft) => {
        switch (type) {
            case SET_ALERT:
                draft.push(payload);
                return draft;
            case REMOVE_ALERT:
                draft.splice(draft.indexOf((alert) => alert.id === payload));
                // draft.filter((alert) => alert.id !== payload);
                return draft;
            default:
                return draft;
        }
    });
};

export default alertReducer;
