import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import AccountSetupLoader from "./AccountSetupLoader";
import BusinessDetailsForm from "../account/BusinessDetailsForm";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Title from "../dashboard/Title";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import featureFlags from "../../featureFlags";

const PostRegister = () => {
    const dispatch = useDispatch();
    const registrationDetails = useSelector((state) => state.auth.registration);
    const [collectingDetails, setCollectingDetails] = useState(true);
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({
        accountId: "",
        name: "",
        website: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        firstName: "",
        lastName: "",
        email: "",
        contactPhone: "",
        messageVolume: "",
        roezanPhones: "",
        useCaseCategory: "",
        useCaseSummary: "",
        messageContent: "",
        optinWorkflowDesc: "",
        optinImageURLs: "",
        privacyPolicyURL: "",
        termsOfServiceURL: "",
        additionalDetails: "",
    });

    useEffect(() => {
        if (registrationDetails) {
            setDetails((prevDetails) => ({
                ...prevDetails,
                accountId: registrationDetails.accountId || "",
                firstName: registrationDetails.firstName || "",
                lastName: registrationDetails.lastName || "",
                name: registrationDetails.friendlyName || "",
                email: registrationDetails.email || "",
            }));
        }
    }, [registrationDetails]);

    const saveDetails = async () => {
        try {
            setLoading(true);
            const result = await axios.post("/api/account/business-details-alt", details, {
                headers: { "Content-Type": "application/json" },
            });
            if (!result?.data?.businessDetails?.accepted) {
                dispatch(setAlert("Business Details updated, but not finished, please resume ASAP", "warning", 8000));
            } else {
                dispatch(setAlert("Business Details submitted, we'll contact you soon!", "success", 8000));
            }
            setCollectingDetails(false);
            setLoading(false);
        } catch (err) {
            dispatch(setAlert("Business Details could not be saved: " + err.message, "error", 8000));
            setLoading(false);
        }
    };
    const submitDetails = () => {
        saveDetails();
    };
    return (
        <>
            {featureFlags.BUSINESS_VERIFY && collectingDetails ? (
                <Container maxWidth="md" sx={{ height: "100%", p: "30px" }}>
                    <Card>
                        <CardHeader title={<Title>We need info about your business</Title>} />
                        <CardContent sx={{ p: "20px !important" }}>
                            <BusinessDetailsForm details={details} setDetails={setDetails} />
                        </CardContent>
                        <CardActions>
                            <Button
                                disabled={loading}
                                color="secondary"
                                variant="contained"
                                fullWidth
                                onClick={submitDetails}
                            >
                                {"Save for later"}
                            </Button>
                            <Button
                                disabled={loading}
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={submitDetails}
                            >
                                {"Submit Details"}
                            </Button>
                        </CardActions>
                    </Card>
                </Container>
            ) : (
                <AccountSetupLoader />
            )}
        </>
    );
};

export default PostRegister;
