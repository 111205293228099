import React from "react"; /*{ Profiler }*/
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = createRoot(document.getElementById("root"));
// const onRenderCallback = (
//     id, // the "id" prop of the Profiler tree that has just committed
//     phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//     actualDuration, // time spent rendering the committed update
//     baseDuration, // estimated time to render the entire subtree without memoization
//     startTime, // when React began rendering this update
//     commitTime, // when React committed this update
//     interactions // the Set of interactions belonging to this update
// ) => {
//     console.log(id, phase, actualDuration, baseDuration, startTime, commitTime, interactions);
// };

root.render(
    <React.StrictMode>
        {/* <Profiler id="App" onRender={onRenderCallback}> */}
        <App />
        {/* </Profiler> */}
    </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
