import { createSlice } from "@reduxjs/toolkit";
import { accountIntegrationsApi } from "./accountIntegrationsApi";

// Events slice
const accountIntegrationsSlice = createSlice({
    name: "events",
    initialState: {
        list: [],
        loading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(accountIntegrationsApi.endpoints.getAllAccountIntegrations.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(accountIntegrationsApi.endpoints.getAllAccountIntegrations.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(accountIntegrationsApi.endpoints.getAllAccountIntegrations.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default accountIntegrationsSlice.reducer;

// export const {  } = accountIntegrationsSlice.actions;
