import React from "react";
import { Autocomplete, Grid, MenuItem, Select, TextField, Typography, FormHelperText } from "@mui/material";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import RzFormControl from "../custom/RzFormControl";

const BusinessDetailsForm = ({ details, setDetails, viewOnly }) => {
    const handleChange = (e) => {
        setDetails((prevDetails) => ({ ...prevDetails, [e.target.name]: e.target.value }));
    };
    return (
        <Grid container spacing={3}>
            {!viewOnly && (
                <>
                    <Grid item sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <AssignmentLateOutlinedIcon color="secondary" />
                        <Typography variant="text-md" color="primary.dark" sx={{ alignItems: "center" }}>
                            Before you begin using Roezan, we need you to provide your <b>Business Details</b>, not your{" "}
                            <em>personal</em> details.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="text-md" color="primary.dark" gutterBottom>
                            We need this to submit more information to the cell carriers for verification to enable
                            message sending. Every SMS service provider is required to collect this information and
                            submit it to each cell carrier.{" "}
                            <b>You cannot send any messages until this information is verified and submitted.</b>
                        </Typography>
                    </Grid>
                </>
            )}
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="name"
                    value={details?.name}
                    onChange={handleChange}
                    size="small"
                    label="Company Name"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="website"
                    value={details?.website}
                    onChange={handleChange}
                    size="small"
                    label="Website"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="address1"
                    value={details?.address1}
                    onChange={handleChange}
                    size="small"
                    label="Address 1"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    fullWidth
                    name="address2"
                    value={details?.address2}
                    onChange={handleChange}
                    size="small"
                    label="Address 2"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="city"
                    value={details?.city}
                    onChange={handleChange}
                    size="small"
                    label="City"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="state"
                    value={details?.state}
                    onChange={handleChange}
                    size="small"
                    label="State"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="zip"
                    value={details?.zip}
                    onChange={handleChange}
                    size="small"
                    label="Zip"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="country"
                    value={details?.country}
                    onChange={handleChange}
                    size="small"
                    label="Country"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="firstName"
                    value={details?.firstName}
                    onChange={handleChange}
                    size="small"
                    label="Contact First Name"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    fullWidth
                    name="lastName"
                    value={details?.lastName}
                    onChange={handleChange}
                    size="small"
                    label="Contact Last Name"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="email"
                    value={details?.email}
                    onChange={handleChange}
                    size="small"
                    label="Contact Email"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    fullWidth
                    name="contactPhone"
                    value={details?.contactPhone}
                    onChange={handleChange}
                    size="small"
                    label="Contact Phone"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    value={details?.messageVolume || 10}
                    size="small"
                    label="Message Volume"
                    helperText="About how many messages-per-month do you plan on sending?"
                    disabled={viewOnly}
                    input={
                        <Select
                            onChange={handleChange}
                            value={details?.messageVolume || 10}
                            name="messageVolume"
                            sx={{ mt: "16px" }}
                            defaultValue=""
                            displayEmpty
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                            <MenuItem value="1000">1,000</MenuItem>
                            <MenuItem value="10000">10,000</MenuItem>
                            <MenuItem value="100000">100,000</MenuItem>
                            <MenuItem value="250000">250,000</MenuItem>
                            <MenuItem value="500000">500,000</MenuItem>
                            <MenuItem value="750000">750,000</MenuItem>
                            <MenuItem value="1000000">1,000,000</MenuItem>
                            <MenuItem value="5000000">5,000,000</MenuItem>
                            <MenuItem value="10000000">10,000,000+</MenuItem>
                        </Select>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    value={details?.useCaseCategory}
                    onChange={handleChange}
                    size="small"
                    label="Use Case"
                    helperText="What is your primary SMS message topic?"
                    disabled={viewOnly}
                    input={
                        <Select
                            onChange={handleChange}
                            name="useCaseCategory"
                            value={details?.useCaseCategory}
                            sx={{ mt: "16px" }}
                            defaultValue=""
                            displayEmpty
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="2FA">2FA</MenuItem>
                            <MenuItem value="App Notifications">App Notifications</MenuItem>
                            <MenuItem value="Appointments">Appointments</MenuItem>
                            <MenuItem value="Auctions">Auctions</MenuItem>
                            <MenuItem value="Auto Repair Services">Auto Repair Services</MenuItem>
                            <MenuItem value="Bank Transfers">Bank Transfers</MenuItem>
                            <MenuItem value="Billing">Billing</MenuItem>
                            <MenuItem value="Booking Confirmations">Booking Confirmations</MenuItem>
                            <MenuItem value="Business Updates">Business Updates</MenuItem>
                            <MenuItem value="COVID-19 Alerts">COVID-19 Alerts</MenuItem>
                            <MenuItem value="Career Training">Career Training</MenuItem>
                            <MenuItem value="Chatbot">Chatbot</MenuItem>
                            <MenuItem value="Conversational/Alerts">Conversational/Alerts</MenuItem>
                            <MenuItem value="Courier Services & Deliveries">Courier Services & Deliveries</MenuItem>
                            <MenuItem value="Emergency Alerts">Emergency Alerts</MenuItem>
                            <MenuItem value="Events & Planning">Events & Planning</MenuItem>
                            <MenuItem value="Financial Services">Financial Services</MenuItem>
                            <MenuItem value="Fraud Alerts">Fraud Alerts</MenuItem>
                            <MenuItem value="Fundraising">Fundraising</MenuItem>
                            <MenuItem value="General Marketing">General Marketing</MenuItem>
                            <MenuItem value="General School Updates">General School Updates</MenuItem>
                            <MenuItem value="HR/Staffing">HR/Staffing</MenuItem>
                            <MenuItem value="Healthcare Alerts">Healthcare Alerts</MenuItem>
                            <MenuItem value="Housing Community Updates">Housing Community Updates</MenuItem>
                            <MenuItem value="Insurance Services">Insurance Services</MenuItem>
                            <MenuItem value="Job Dispatch">Job Dispatch</MenuItem>
                            <MenuItem value="Legal Services">Legal Services</MenuItem>
                            <MenuItem value="Mixed">Mixed</MenuItem>
                            <MenuItem value="Motivational Reminders">Motivational Reminders</MenuItem>
                            <MenuItem value="Notary Notifications">Notary Notifications</MenuItem>
                            <MenuItem value="Order Notifications">Order Notifications</MenuItem>
                            <MenuItem value="Political">Political</MenuItem>
                            <MenuItem value="Public Works">Public Works</MenuItem>
                            <MenuItem value="Real Estate Services">Real Estate Services</MenuItem>
                            <MenuItem value="Religious Services">Religious Services</MenuItem>
                            <MenuItem value="Repair and Diagnostics Alerts">Repair and Diagnostics Alerts</MenuItem>
                            <MenuItem value="Rewards Program">Rewards Program</MenuItem>
                            <MenuItem value="Surveys">Surveys</MenuItem>
                            <MenuItem value="System Alerts">System Alerts</MenuItem>
                            <MenuItem value="Voting Reminders">Voting Reminders</MenuItem>
                            <MenuItem value="Waitlist Alerts">Waitlist Alerts</MenuItem>
                            <MenuItem value="Webinar Reminders">Webinar Reminders</MenuItem>
                            <MenuItem value="Workshop Alerts">Workshop Alerts</MenuItem>
                        </Select>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="useCaseSummary"
                    value={details?.useCaseSummary}
                    onChange={handleChange}
                    size="small"
                    label="Use Case Summary"
                    multiline
                    maxRows={3}
                    inputProps={{
                        maxLength: 2000,
                    }}
                    helperText={"How are you using texting in your business"}
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="messageContent"
                    value={details?.messageContent}
                    onChange={handleChange}
                    size="small"
                    label="Message Content"
                    multiline
                    maxRows={3}
                    inputProps={{
                        maxLength: 2000,
                    }}
                    helperText="What does a typical message from you look like?"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="optinWorkflowDesc"
                    value={details?.optinWorkflowDesc}
                    onChange={handleChange}
                    size="small"
                    label="Opt-in Workflow Desc."
                    multiline
                    maxRows={3}
                    helperText="How does a contact opt-in to your messaging?"
                    disabled={viewOnly}
                    input={
                        <Autocomplete
                            freeSolo
                            size="small"
                            options={["Online", "Text-to-join", "Point-of-sale"]}
                            value={details?.optinWorkflowDesc || ""}
                            onChange={(e, value) => {
                                setDetails((prevDetails) => ({ ...prevDetails, optinWorkflowDesc: value }));
                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Select or type answer" sx={{ mt: "16px" }} />
                            )}
                            disabled={viewOnly}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="optinImageURLs"
                    value={details?.optinImageURLs}
                    onChange={handleChange}
                    size="small"
                    label={"Opt-in Image URL(s)"}
                    labelDescription={
                        <FormHelperText
                            id="rz-label-description"
                            sx={{ marginTop: "15px", marginBottom: "10px", fontWeight: "bold" }}
                        >
                            Your opt-in must include compliance text to be approved.
                            <br />
                            See requirements{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://help.roezan.com/help/opt-in-page-sms-compliance-requirements"
                            >
                                here
                            </a>
                            .
                        </FormHelperText>
                    }
                    multiline
                    maxRows={3}
                    helperText="Image(s) of your opt-in form. Separate multiple URLs with a semicolon (;)"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="privacyPolicyURL"
                    value={details?.privacyPolicyURL}
                    onChange={handleChange}
                    size="small"
                    label="Privacy Policy URL"
                    labelDescription={
                        <FormHelperText
                            id="rz-label-description"
                            sx={{ marginTop: "15px", marginBottom: "10px", fontWeight: "bold" }}
                        >
                            Get more information and see guidelines{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://help.roezan.com/help/sample-terms-of-service-privacy-policy-terms-for-sms"
                            >
                                here
                            </a>
                            .
                        </FormHelperText>
                    }
                    multiline
                    maxRows={3}
                    inputProps={{
                        maxLength: 2000,
                    }}
                    helperText="What is the URL of your Privacy Policy?"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RzFormControl
                    required
                    fullWidth
                    name="termsOfServiceURL"
                    value={details?.termsOfServiceURL}
                    onChange={handleChange}
                    size="small"
                    label="Terms of Service URL"
                    labelDescription={
                        <FormHelperText
                            id="rz-label-description"
                            sx={{ marginTop: "15px", marginBottom: "10px", fontWeight: "bold" }}
                        >
                            Get more information and see guidelines{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://help.roezan.com/help/sample-terms-of-service-privacy-policy-terms-for-sms"
                            >
                                here
                            </a>
                            .
                        </FormHelperText>
                    }
                    multiline
                    maxRows={3}
                    inputProps={{
                        maxLength: 2000,
                    }}
                    helperText="What is the URL of your Terms of Service?"
                    disabled={viewOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <RzFormControl
                    fullWidth
                    name="additionalDetails"
                    value={details?.additionalDetails}
                    onChange={handleChange}
                    size="small"
                    label="Additional Details"
                    multiline
                    maxRows={3}
                    disabled={viewOnly}
                />
            </Grid>
        </Grid>
    );
};

export default BusinessDetailsForm;
