const white = "#FFFFFF";
const offWhite = "#F8F6F2";
const neutral = {
    900: "#111111",
    800: "#313131",
    700: "#4F4F4F",
    600: "#626262",
    500: "#898989",
    400: "#AAAAAA",
    300: "#CFCFCF",
    200: "#E1E1E1",
    100: "#EEEEEE",
    50: "#F7F7F7",
};

const iceberg = "#D2F1E4";
const blueChill = "#129490";
const tiber = {
    100: "#093A3E",
    80: "#3A6165",
    70: "#537578",
    30: "#B5C4C5",
    10: "#E6EBEC",
    5: "#F3F5F5",
};

const flamingo = "#D63737";
const cosmos = "#F8E3E3";
const springwood = "#F8F6F2";
const tulipTree = "#EFB43D";

const text = {
    "display-sm": {
        fontSize: "1.875rem",
        lineHeight: "2.375rem",
    },

    "display-xs": {
        fontSize: "1.5rem",
        lineHeight: "2rem",
    },
    "text-xl": {
        fontSize: "1.25rem",
        lineHeight: "1.875rem",
    },
    "text-md": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
    },

    "text-sm": {
        fontSize: "0.875rem",
        lineHeight: "1.25em",
    },

    "text-xs": {
        fontSize: "0.75rem",
        lineHeight: "1.125rem",
    },
};

const weight = {
    semibold: 600,
    bold: 700,
};

const drawerWidth = 280;

const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

module.exports = {
    white,
    offWhite,
    neutral,
    iceberg,
    blueChill,
    tiber,
    flamingo,
    cosmos,
    springwood,
    tulipTree,
    text,
    weight,
    drawerWidth,
    transition,
};
