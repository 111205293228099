import produce from 'immer';
import { TOGGLE_BACKSTAGE } from '../actions/types';

const initialState = {
    active: false,
};

const backstageReducer = (state = initialState, action) => {
    const { type } = action; //payload not used
    return produce(state, (draft) => {
        switch (type) {
            case TOGGLE_BACKSTAGE:
                draft.active = !draft.active;

                return draft;
            default:
                return draft;
        }
    });
};

export default backstageReducer;
