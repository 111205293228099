import produce from 'immer';
import {
    CREATE_KEYWORD_ERROR,
    CREATE_KEYWORD_REQUEST,
    CREATE_KEYWORD_SUCCESS,
    CREATE_KEYWORD_ACTION_ERROR,
    CREATE_KEYWORD_ACTION_REQUEST,
    CREATE_KEYWORD_ACTION_SUCCESS,
    DELETE_KEYWORD_ERROR,
    DELETE_KEYWORD_REQUEST,
    DELETE_KEYWORD_SUCCESS,
    DELETE_KEYWORD_ACTION_ERROR,
    DELETE_KEYWORD_ACTION_REQUEST,
    DELETE_KEYWORD_ACTION_SUCCESS,
    GET_KEYWORDS_ERROR,
    GET_KEYWORDS_REQUEST,
    GET_KEYWORDS_SUCCESS,
    LOGOUT,
    SET_SELECTED_KEYWORD,
    UPDATE_KEYWORD_ERROR,
    UPDATE_KEYWORD_REQUEST,
    UPDATE_KEYWORD_SUCCESS,
    UPDATE_KEYWORD_ACTION_ERROR,
    UPDATE_KEYWORD_ACTION_REQUEST,
    UPDATE_KEYWORD_ACTION_SUCCESS,
} from '../actions/types';

const initialState = {
    error: false,
    list: [],
    loading: false,
    selectedKeyword: null,
};

const keywordsReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case CREATE_KEYWORD_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case CREATE_KEYWORD_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case CREATE_KEYWORD_SUCCESS:
                draft.error = false;
                draft.list.unshift(payload);
                draft.loading = false;
                return draft;

            case CREATE_KEYWORD_ACTION_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case CREATE_KEYWORD_ACTION_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case CREATE_KEYWORD_ACTION_SUCCESS:
                draft.error = true;
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1,
                    payload
                );
                draft.selectedKeyword = payload;
                return draft;

            case DELETE_KEYWORD_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case DELETE_KEYWORD_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case DELETE_KEYWORD_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1
                );
                draft.selectedKeyword = null;
                return draft;

            case DELETE_KEYWORD_ACTION_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case DELETE_KEYWORD_ACTION_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case DELETE_KEYWORD_ACTION_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1,
                    payload
                );
                draft.selectedKeyword = payload;
                return draft;

            case GET_KEYWORDS_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case GET_KEYWORDS_REQUEST:
                draft.list = [];
                draft.error = false;
                draft.loading = true;
                return draft;
            case GET_KEYWORDS_SUCCESS:
                draft.error = false;
                draft.list = payload || [];
                draft.loading = false;
                return draft;
            case SET_SELECTED_KEYWORD:
                draft.selectedKeyword = payload;
                return draft;
            case UPDATE_KEYWORD_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case UPDATE_KEYWORD_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case UPDATE_KEYWORD_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.selectedKeyword = payload;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1,
                    payload
                );
                return draft;

            case UPDATE_KEYWORD_ACTION_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case UPDATE_KEYWORD_ACTION_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case UPDATE_KEYWORD_ACTION_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1,
                    payload
                );
                draft.selectedKeyword = payload;
                return draft;
            default:
                return draft;
        }
    });
};

export default keywordsReducer;
