import axios from 'axios';
const setAuthHeader = (token) => {
	if (token) {
		// Apply authorization token to every request if logged in
		axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
	} else {
		// Delete auth header
		delete axios.defaults.headers.common['Authorization'];
	}
};
export default setAuthHeader;
