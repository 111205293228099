import axios from "axios";
import {
    ARCHIVE_CONVERSATION_ERROR,
    ARCHIVE_CONVERSATION_REQUEST,
    ARCHIVE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    CREATE_CONVERSATION_REQUEST,
    CREATE_CONVERSATION_SUCCESS,
    CLEAR_CURRENT_CONVERSATION,
    CREATE_DRAFT_CONVERSATION,
    DELETE_DRAFT_CONVERSATION,
    GET_CONVERSATION_LIST_ERROR,
    GET_CONVERSATION_LIST_REQUEST,
    GET_CONVERSATION_LIST_SUCCESS,
    OPTIN_CONTACT_SUCCESS,
    OPTOUT_CONTACT_SUCCESS,
    SELECT_DRAFT_CONTACT,
    SET_CURRENT_CONVERSATION,
    UPDATE_CONVERSATION_THREAD,
    UPDATE_CONVERSATION_SUCCESS,
    GET_ALL_CONVERSATION_LIST_REQUEST,
    GET_ALL_CONVERSATION_LIST_SUCCESS,
    GET_ALL_CONVERSATION_LIST_ERROR,
    SET_STATUS_ID,
} from "./types";
import { setAlert } from "./alert";

const head = {
    headers: {
        "Content-Type": "application/json",
    },
};

// Marks contact as inbox=0 or not as having an active conversation
//  conversation history remains saved and can be reopened
export const archiveConversation = (conversation) => async (dispatch) => {
    try {
        dispatch({
            type: ARCHIVE_CONVERSATION_REQUEST,
        });

        let conversationUpdate = {};
        Object.assign(conversationUpdate, conversation);
        conversationUpdate.active = false;

        const res = await axios.put(`/api/conversations/${conversation.id}`, { conversationUpdate }, head);
        dispatch({
            type: ARCHIVE_CONVERSATION_SUCCESS,
            payload: res.data,
        });
        dispatch(setAlert("Conversation Archived Successfully", "success"));
    } catch (err) {
        dispatch({
            type: ARCHIVE_CONVERSATION_ERROR,
            payload: err.message,
        });
        dispatch(setAlert("Unable to Archive Conversation", "warning"));
    }
};

export const createConversation =
    (accountPhoneId, contactId, history = null) =>
    async (dispatch) => {
        try {
            dispatch({
                type: CREATE_CONVERSATION_REQUEST,
            });
            const res = await axios.post(
                "/api/conversations",
                { accountPhoneId: accountPhoneId, contactId: contactId },
                head
            );

            dispatch({
                type: CREATE_CONVERSATION_SUCCESS,
                payload: res.data,
            });

            if (history) {
                //`/admin/conversations/contact/${contactId}`
                history.push({
                    pathname: `/admin/conversations`,
                    //search: '?query=abc',
                    state: { contactId },
                });
            }
        } catch (err) {
            if (err.response.status === 400) {
                dispatch(setAlert(err.response.data?.message, "warning", 10000));
            }
            dispatch({
                type: CREATE_CONVERSATION_ERROR,
                payload: err.message,
            });
        }
    };

export const getAllConversations = (query, orderBy, conversationId, contactId) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_CONVERSATION_LIST_REQUEST,
        });
        const searchParams = new URLSearchParams();
        query?.length > 0 && searchParams.append("q", query);
        orderBy?.length > 0 && searchParams.append("order_by", orderBy);
        contactId?.length > 0 && searchParams.append("contact_id", contactId);
        conversationId?.length > 0 && searchParams.append("conversation_id", conversationId);

        const uri = "/api/conversations?";

        const res = await axios.get(uri + searchParams.toString(), head);

        dispatch({
            type: GET_ALL_CONVERSATION_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_CONVERSATION_LIST_ERROR,
            payload: err.message,
        });
    }
};

export const getConversationList =
    (page, size, query, orderBy, conversationId, contactId, statusId) => async (dispatch) => {
        try {
            dispatch({
                type: GET_CONVERSATION_LIST_REQUEST,
                payload: page,
            });
            const uri = "/api/conversations?";
            const searchParams = new URLSearchParams();
            searchParams.append("page", page);
            searchParams.append("size", size);
            searchParams.append("order_by", orderBy);
            query?.length > 0 && searchParams.append("q", query);
            contactId?.length > 0 && searchParams.append("contact_id", contactId);
            conversationId?.length > 0 && searchParams.append("conversation_id", conversationId);
            statusId !== null && searchParams.append("status_id", statusId);

            const res = await axios.get(uri + searchParams.toString(), head);

            dispatch({
                type: GET_CONVERSATION_LIST_SUCCESS,
                payload: res.data,
            });
            if (page === 1 && !res.data.matches) {
                if (query && query.length > 0) {
                    dispatch(setAlert("No Matches", "info", 1000));
                }
            }
            // history.push(`/verify/${formData.email}`);
        } catch (err) {
            dispatch({
                type: GET_CONVERSATION_LIST_ERROR,
                payload: err.message,
            });
        }
    };

export const clearCurrentConversation = () => async (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_CONVERSATION });
};

export const draftNewConversation = (history) => async (dispatch) => {
    dispatch({
        type: CREATE_DRAFT_CONVERSATION,
        payload: {
            id: -1 * Math.random().toString(10).substring(2, 5),
        },
    });
    if (history) {
        history.push(`/admin/conversations`);
    }
};

export const deleteDraftConversation = (convo) => async (dispatch) => {
    dispatch({ type: DELETE_DRAFT_CONVERSATION, payload: convo });
};

export const selectDraftContact = (accountPhoneId, contact) => async (dispatch) => {
    try {
        const res = await axios.post(
            "/api/conversations",
            { accountPhoneId: accountPhoneId, contactId: contact.id },
            head
        );
        dispatch({
            type: SELECT_DRAFT_CONTACT,
            payload: { conversation: res.data.convo.conversation },
        });
    } catch (err) {
        if (err.response.status === 400) {
            dispatch(setAlert(err.response.data?.message, "warning", 10000));
        }
        setAlert("An error occurred while starting the Conversation, please try again", "error", 6000);
    }
};
export const setSelectedConversation = (convo, history) => async (dispatch) => {
    try {
        if (convo.id > 0) {
            let conversationUpdate = {};
            Object.assign(conversationUpdate, convo);
            conversationUpdate.unread = false;
            const res = await axios.put(`/api/conversations/${convo.id}`, { conversationUpdate }, head);
            dispatch({
                type: UPDATE_CONVERSATION_SUCCESS,
                payload: res.data.conversation,
            });
            dispatch({
                type: SET_CURRENT_CONVERSATION,
                payload: res.data.conversation,
                drafting: convo.id < 0,
            });
            if (history) {
                //`/admin/conversations/contact/${res.data.conversation.contact_id}`
                history.push({
                    pathname: `/admin/conversations`,
                    state: { contactId: res.data.conversation.contact_id },
                });
            }
        } else {
            dispatch({
                type: SET_CURRENT_CONVERSATION,
                payload: convo,
                drafting: convo.id < 0,
            });
        }
    } catch (err) {
        // dispatch({
        // 	type: SET_CURRENT_CONVERSATION_ERROR,
        // 	payload: { contact: contact, conversation: null },
        // });
        console.log("SetSelectedConversation", err.message);
    }
};

export const setStatusId = (statusId) => {
    return {
        type: SET_STATUS_ID,
        payload: statusId,
    };
};

export const updateConversationThread = (event, update) => {
    return {
        type: UPDATE_CONVERSATION_THREAD,
        event: event,
        payload: update,
    };
};

export const updateOptFromConvo = (conversation, optIn) => async (dispatch) => {
    try {
        // contact.opted_in = false;
        let convo = {};
        Object.assign(convo, conversation);

        const body = { optin: optIn };
        const res = await axios.put(`/api/contacts/${conversation.contact.id}/opt`, body, head);

        convo.contact = res.data.contact;
        dispatch({
            type: optIn ? OPTIN_CONTACT_SUCCESS : OPTOUT_CONTACT_SUCCESS,
            payload: convo,
        });
        dispatch({ type: SET_CURRENT_CONVERSATION, payload: convo });
        dispatch(setAlert(optIn ? "Contact Opted In Successfully!" : "Contact Opted Out Successfully!", "success"));
    } catch (err) {
        console.log(err);
        // dispatch({
        // 	type: optIn ? OPTIN_CONTACT_ERROR : OPTOUT_CONTACT_ERROR,
        // 	payload: err.message,
        // });
        dispatch(setAlert(optIn ? "Error Occurred Opting In Contact" : "Error Occurred Opting Out Contact", "error"));
    }
};

export const updateConversation =
    (conversationId, { active, statusId }) =>
    async (dispatch) => {
        try {
            const body = { conversationUpdate: { active, statusId } };
            const res = await axios.put(`/api/conversations/${conversationId}`, body, head);

            dispatch({ type: UPDATE_CONVERSATION_SUCCESS, payload: res.data.conversation });
            switch (res.data.conversation?.status_id) {
                case 1:
                    dispatch(setAlert("Conversation moved to status: Open", "success"));
                    break;
                case 2:
                    dispatch(setAlert("Conversation moved to status: Pending", "success"));
                    break;
                case 3:
                    dispatch(setAlert("Conversation moved to status: Closed", "success"));
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.log("updateConversation", err);
            dispatch(setAlert("Error occured while updating Conversation", "error"));
        }
    };
