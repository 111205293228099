import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";

const RzFormControl = (props) => {
    const {
        label,
        labelDescription,
        tooltip,
        helperText,
        placeholder,
        value,
        onChange,
        size,
        type,
        name,
        input,
        inputProps,
        error,
        multiline = false,
        maxRows = null,
        autoFocus = null,
        ...rest
    } = props;

    return (
        <FormControl variant="outlined" size={size} {...rest} error={error}>
            <InputLabel
                htmlFor="rz-input"
                shrink
                sx={{
                    left: "-13px",
                    transform: "translate(14px, -9px) scale(0.9)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                {label}
                {tooltip}
            </InputLabel>
            {labelDescription}
            {input ? (
                input
            ) : (
                <OutlinedInput
                    id="rz-input"
                    autoFocus={autoFocus}
                    multiline={multiline}
                    aria-describedby="rz-helper-text"
                    placeholder={placeholder}
                    name={name}
                    type={type}
                    onChange={onChange}
                    value={value}
                    maxRows={maxRows}
                    inputProps={inputProps}
                    sx={{
                        position: "relative",
                        "label + &": {
                            mt: "16px",
                        },
                    }}
                />
            )}
            {helperText && <FormHelperText id="rz-helper-text">{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default RzFormControl;
