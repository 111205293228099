import axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { setAlert } from "./alert";
import {
    LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_ERROR,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    SET_CURRENT_USER,
    VERIFY_RESEND_REQUEST,
    VERIFY_RESEND_SUCCESS,
    VERIFY_RESEND_ERROR,
    VERIFY_TOKEN_ERROR,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
} from "./types";
import setAuthHeader from "../utils/setAuthHeader";

const relativeURL = process.env.REACT_APP_NODE_ENV === "production" ? "" : "";

const head = {
    headers: {
        "Content-Type": "application/json",
    },
};

// Deprecated, we are pushing registration with payment now (see `registerWithPayment`)
export const register = (formData, history) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTER_REQUEST,
        });
        //TODO rework how we receive plan ID on registration
        formData.planId = 1;

        const res = await axios.post(relativeURL + "/api/auth/register", formData, head);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });
        res.data.user.role === "primary" ? history.push("/purchase") : history.push("/login");
        dispatch(setAlert("Registration Successful", "success"));
        // history.push(`/verify/${formData.email}`);
    } catch (err) {
        dispatch({
            type: REGISTER_ERROR,
            payload: err.message,
        });
        dispatch(setAlert("Registration Failed, Please Try Again", "warning"));
    }
};

/**
 * @description Attempt Account & User registration while simultaneously accepting payment information
 * @param {Object} formData registration details
 * @param {string} paymentMethodId Stripe payment method ID
 * @param {*} history
 * @returns void
 */
export const registerWithPayment = (formData, paymentMethodId, history) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTER_REQUEST,
        });
        const body = { ...formData, paymentMethodId };
        console.log("RegisterWithPayment body: ", body);

        const res = await axios.post(relativeURL + "/api/auth/register-full", body, head);
        console.log("RegisterWithPayment response: ", res?.data);

        dispatch(setAlert("Registration Successful", "success"));
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });

        if (window.fpr) {
            var email = formData.email;
            window.fpr("referral", { email: email });
        }
        history.push("/account-setup");
        // history.push(`/verify/${formData.email}`);
    } catch (err) {
        console.log(err.message, err.response);
        dispatch({
            type: REGISTER_ERROR,
            payload: err.message,
        });
        if (err.response && err.response.status === 400) {
            dispatch(
                setAlert(
                    err.response.data.message ? err.response.data.message : "Registration Failed, Please Try Again",
                    "warning",
                    5000
                )
            );
        } else {
            dispatch(setAlert("Registration Failed, Please Try Again", "warning"));
        }
    }
};

export const login = (formData, history, locationState) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST,
        });
        const res = await axios.post(relativeURL + "/api/auth/login", formData, head);
        // Set token to localStorage
        const { token } = res.data;
        localStorage.setItem("roezan", token);
        setAuthHeader(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);

        // Set current user
        dispatch(setCurrentUser(decoded));
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });

        if (process.env.NODE_ENV === "production") {
            console.log("Production Scripts Loading");

            // ------- PROFITWELL IDENTIFY -------
            window.profitwell && window.profitwell("start", { user_email: decoded.email });

            // ------- FULLSTORY IDENTIFY -------
            window.FS &&
                window.FS.identify(decoded.id, {
                    displayName: "",
                    email: decoded.email,
                    // TODO: Add your own custom user variables here, details at
                    // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
                    //reviewsWritten_int: 14
                });

            // ------- INTERCOM BOOT -------
            const INTERCOM_WS_ID = process.env.REACT_APP_INTERCOM_WORKSPACE_ID;
            if (!window.Intercom) {
                console.log("Intercom not found, instantiating");
                window.intercomSettings = {
                    app_id: INTERCOM_WS_ID,
                    email: decoded.email,
                    account_id: decoded.account_id,
                    user_id: decoded.id,
                };

                // prettier-ignore
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + INTERCOM_WS_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            }

            // Setup Intercom for current user once logged in
            window.Intercom("boot", {
                app_id: INTERCOM_WS_ID,
                email: decoded.email,
                account_id: decoded.account_id,
                user_id: decoded.id,
            });
            // ------- END INTERCOM -------

            // ------- GTM DATALAYER -------
            if (window.dataLayer) {
                // Profitwell event - original approach, testing above native call
                window.dataLayer.push({
                    event: "start_pw",
                    pw_user_email: decoded.email,
                });
            }
            // ------- END GTM DATALAYER -------
        } else {
            console.log(`Production Scripts not Loading for ${process.env.NODE_ENV}`);
        }

        if (!res.data.user.verified || !res.data.account.verified) {
            history.push("/verify");
        } else if (locationState && locationState.from) {
            history.push(`${locationState.from.pathname}${locationState.from.search}`);
        } else {
            history.push("/admin");
        }
    } catch (err) {
        console.log("Login Error", err.response);
        dispatch({
            type: LOGIN_ERROR,
            payload: {
                msg: err.response?.statusText || "An unknown error occurred, please try again",
                status: err.response.status,
            },
        });
    }
};

export const sendVerifyCode = (email, phone) => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_RESEND_REQUEST, payload: email });
        const body = JSON.stringify({ email, phone });

        const res = await axios.post(relativeURL + "/api/auth/verify", body, head);
        dispatch({ type: VERIFY_RESEND_SUCCESS, payload: res.data });
        dispatch(setAlert("Verification Code Sent", "success"));
    } catch (err) {
        dispatch({
            type: VERIFY_RESEND_ERROR,
            payload:
                "Unable to send Verification Token. Either Register for a new account, login to the one entered or contact support.",
        });
    }
};

export const verifyCode = (token, history) => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_TOKEN_REQUEST });
        const res = await axios.get(relativeURL + "api/auth/verify/" + token, head);
        if (res.data.status === "success") {
            await dispatch({ type: VERIFY_TOKEN_SUCCESS, payload: res.data });
            //history.push('/admin');
            dispatch(setAlert("Your Account is now Verified, Redirecting...", "success"));
            setTimeout(() => history.push("/admin"), 1000);
        } else {
            dispatch({ type: VERIFY_TOKEN_ERROR });
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch({ type: VERIFY_TOKEN_ERROR });
    }
};

export const changePassword = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(relativeURL + "/api/auth/change-password", formData, head);
        dispatch(setAlert(res.data.message, "success"));
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: res.data });
        return res.data;
    } catch (err) {
        console.error(err);
        dispatch(
            setAlert(
                err.response?.data?.message || "Unknown error occurred while changing password, try again",
                "warning"
            )
        );
    }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
    console.log("Issued At: ", moment.unix(decoded.iat).format());
    console.log("Expires At: ", moment.unix(decoded.exp).format());
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

// Log user out
export const logoutUser = () => (dispatch) => {
    // Remove token from local storage
    localStorage.removeItem("roezan");
    // Remove auth header for future requests
    setAuthHeader(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
    dispatch({ type: LOGOUT });

    // Remove Intercom once logged out
    window.Intercom && window.Intercom("shutdown");
};
