import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    reducerPath: "api",
    tagTypes: [
        "Accounts",
        "AccountIntegrations",
        "AppSettings",
        "Broadcasts",
        "Events",
        "EventReminders",
        "EventTypes",
        "Lists",
        "ReminderTemplates",
        "Tags",
        "Users",
        "Messages",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: "/api",
        // credentials: "include",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("roezan");
            if (token) {
                headers.set("authorization", `bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});
