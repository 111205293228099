import React from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.error(error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        console.log(`---------------------------------`);
        console.log(`An Error Occurred`);
        console.log(`Error: `, error);
        console.log(`ErrorInfo: `, errorInfo);
        console.log(`---------------------------------`);
        // const { history } = this.props;
        // setTimeout(() => {
        //     history && history.push('/admin/dashboard');
        // }, 1000);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <center>
                    <h1>Something went wrong.</h1>
                    <h2>Please Reload your page</h2>
                    <h3>
                        If this persists, please contact support at{" "}
                        <a href="mailto:support@roezan.com">support@roezan.com</a>
                    </h3>
                </center>
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
