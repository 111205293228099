import produce from "immer";
import {
    GET_ACCOUNTINTEGRATIONS_ERROR,
    GET_ACCOUNTINTEGRATIONS_REQUEST,
    GET_ACCOUNTINTEGRATIONS_SUCCESS,
} from "../actions/types";

const initialState = {
    loading: false,
    list: [],
};
const accountIntegrationsReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case GET_ACCOUNTINTEGRATIONS_ERROR:
                draft.loading = false;
                return draft;
            case GET_ACCOUNTINTEGRATIONS_REQUEST:
                draft.loading = true;
                return draft;
            case GET_ACCOUNTINTEGRATIONS_SUCCESS:
                draft.loading = false;
                draft.list = payload.accountIntegrations;
                return draft;
            default:
                return draft;
        }
    });
};

export default accountIntegrationsReducer;
