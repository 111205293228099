import produce from "immer";
import {
    // GET_ACCOUNT_REQUEST,
    // GET_ACCOUNT_SUCCESS,
    LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    REGISTER_ERROR,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    SET_CURRENT_USER,
    VERIFY_RESEND_REQUEST,
    VERIFY_RESEND_SUCCESS,
    VERIFY_RESEND_ERROR,
    VERIFY_TOKEN_ERROR,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
} from "../actions/types";
import isEmpty from "is-empty";

const initialState = {
    isAuthenticated: false,
    loading: false,
    error: null,
    account: {},
    user: {},
    registration: {
        accountId: "",
        customerId: "",
        email: "",
        friendlyName: "",
    },
    superAdmin: false,
    verificationChannel: "",
    verificationMessage: "",
    verificationComplete: false,
};

const authReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGIN_REQUEST:
            case REGISTER_REQUEST:
            case VERIFY_RESEND_REQUEST:
            case VERIFY_TOKEN_REQUEST:
                draft.registration = {
                    accountId: "",
                    customerId: "",
                    email: "",
                    friendlyName: "",
                };
                draft.loading = true;
                draft.error = null;
                draft.verificationComplete = false;
                return draft;
            case LOGIN_SUCCESS:
                //Don't set user info here, it's being set with SET_CURRENT_USER below in the action
                console.log("LOGIN PAYLOAD", payload);
                if (payload.superAdmin) {
                    draft.superAdmin = payload.superAdmin;
                }
                draft.isAuthenticated = true;
                draft.error = null;
                draft.loading = false;
                draft.account = payload.account;
                draft.user = payload.user;
                draft.verificationChannel = payload.channel;
                draft.verificationMessage = payload.verifyMessage;
                return draft;
            case REGISTER_SUCCESS:
                draft.loading = false;
                if (payload.user.role === "primary") {
                    draft.registration = {
                        accountId: payload.user.account_id,
                        customerId: payload.stripeCustomer,
                        email: payload.user.email,
                        firstName: payload.user.first_name,
                        friendlyName: payload.friendlyName,
                        lastName: payload.user.last_name,
                        role: payload.user.role,
                    };
                }
                return draft;
            case VERIFY_RESEND_SUCCESS:
                draft.loading = false;
                return draft;
            case LOGIN_ERROR:
            case REGISTER_ERROR:
                draft.isAuthenticated = false;
                draft.loading = false;
                draft.error = payload;
                draft.user = {};
                draft.verificationComplete = false;
                return draft;
            case VERIFY_RESEND_ERROR:
            case VERIFY_TOKEN_ERROR:
                draft.loading = false;
                return draft;
            case SET_CURRENT_USER:
                //Payload here is the decoded token with user information
                draft.isAuthenticated = !isEmpty(payload);
                draft.loading = false;
                draft.error = null;
                draft.user = payload;
                return draft;
            case VERIFY_TOKEN_SUCCESS:
                draft.account = payload.account;
                draft.user = { ...draft.user, ...payload.user };
                draft.loading = false;
                draft.verificationComplete = true;
                return draft;
            default:
                return draft;
        }
    });
};

export default authReducer;
