import produce from 'immer';
import {
    CREATE_CONTACT_ERROR,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    DELETE_CONTACT_ERROR,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    GET_CONTACTS_ERROR,
    GET_CONTACTS_REQUEST,
    GET_CONTACTS_SUCCESS,
    LOGOUT,
    OPTIN_CONTACT_ERROR,
    OPTIN_CONTACT_REQUEST,
    OPTIN_CONTACT_SUCCESS,
    OPTOUT_CONTACT_ERROR,
    OPTOUT_CONTACT_REQUEST,
    OPTOUT_CONTACT_SUCCESS,
    UNSUBSCRIBE_LIST_CONTACT_ERROR,
    UNSUBSCRIBE_LIST_CONTACT_REQUEST,
    UNSUBSCRIBE_LIST_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,
    UPDATE_CONTACT_REQUEST,
    UPDATE_CONTACT_SUCCESS,
} from '../actions/types';

const initialState = {
    createdId: '',
    editedId: '',
    error: null,
    errorMessage: '',
    errorObject: null,
    list: [],
    loading: false,
    pagination: {},
};

const contactReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case CREATE_CONTACT_ERROR:
                draft.createdId = '';
                draft.error = true;
                draft.errorObject = payload;
                draft.loading = false;
                return draft;
            case CREATE_CONTACT_REQUEST:
                draft.createdId = '';
                draft.error = false;
                draft.errorMessage = '';
                draft.loading = true;
                return draft;
            case CREATE_CONTACT_SUCCESS:
                draft.createdId = payload.createdId;
                draft.error = false;
                draft.errorMessage = '';
                draft.list.unshift(payload.contact);
                draft.loading = false;
                return draft;
            case DELETE_CONTACT_ERROR:
                draft.error = true;
                draft.errorMessage = payload.message;
                draft.loading = false;
                return draft;
            case DELETE_CONTACT_REQUEST:
                draft.error = false;
                draft.errorMessage = '';
                draft.loading = true;
                return draft;
            case DELETE_CONTACT_SUCCESS:
                draft.error = false;
                draft.errorMessage = '';
                draft.loading = false;
                draft.list = draft.list.filter(
                    (contact) => contact.id !== parseInt(payload.id)
                );
                return draft;
            case GET_CONTACTS_REQUEST:
                draft.error = false;
                draft.errorMessage = '';
                draft.loading = true;
                // payload.tagId, payload.listId
                return draft;
            // return { ...state, loading: true };
            case GET_CONTACTS_ERROR:
                draft.error = true;
                draft.errorMessage = payload.message;
                draft.list = [];
                draft.loading = false;
                draft.pagination = {};
                return draft;
            // return { ...state, list: [], loading: false, pagination: {} };
            case GET_CONTACTS_SUCCESS:
                draft.error = false;
                draft.errorMessage = '';
                draft.list = payload.contacts;
                draft.loading = false;
                draft.pagination = payload.pagination;
                return draft;
            case OPTIN_CONTACT_REQUEST:
            case OPTOUT_CONTACT_REQUEST:
                draft.loading = true;
                draft.error = false;
                draft.errorMessage = '';
                return draft;
            case OPTIN_CONTACT_ERROR:
            case OPTOUT_CONTACT_ERROR:
                draft.loading = false;
                draft.error = true;
                draft.errorMessage = payload.message;
                return draft;
            case OPTIN_CONTACT_SUCCESS:
            case OPTOUT_CONTACT_SUCCESS:
                draft.errorMessage = '';
                draft.list.splice(
                    draft.list.findIndex(
                        (contact) => contact.id === parseInt(payload.contact.id)
                    ),
                    1,
                    payload.contact
                );
                draft.loading = false;
                return draft;
            case UNSUBSCRIBE_LIST_CONTACT_ERROR:
                draft.error = true;
                draft.errorMessage = payload.message;
                return draft;
            case UNSUBSCRIBE_LIST_CONTACT_REQUEST:
                draft.loading = true;
                draft.error = false;
                draft.errorMessage = '';
                return draft;
            case UNSUBSCRIBE_LIST_CONTACT_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.list.splice(
                    draft.list.findIndex(
                        (contact) =>
                            contact.id.toString() ===
                            payload.contact.id.toString()
                    ),
                    1
                );
                return draft;
            case UPDATE_CONTACT_REQUEST:
                draft.editedId = '';
                draft.errorMessage = '';
                draft.loading = true;
                return draft;
            case UPDATE_CONTACT_ERROR:
                draft.editedId = '';
                draft.error = true;
                draft.errorMessage = payload.message;
                draft.loading = false;
                return draft;
            case UPDATE_CONTACT_SUCCESS:
                draft.editedId = payload.editId;
                draft.errorMessage = '';
                draft.list.splice(
                    draft.list.findIndex(
                        (contact) => contact.id === parseInt(payload.contact.id)
                    ),
                    1,
                    payload.contact
                );
                draft.loading = false;
                return draft;
            default:
                return draft;
        }
    });
};

export default contactReducer;
