import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import roezanLogo from '../../assets/img/rz_logo_full.svg';
import { Box } from '@mui/system';

const Loading = () => {
    return (
        <Backdrop
            open={true}
            sx={{
                backgroundColor: '#FFFFFF',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                src={roezanLogo}
                component='img'
                alt='Roezan'
                sx={{ maxWidth: '200px' }}
            />
            <CircularProgress />
        </Backdrop>
    );
};

export default Loading;
