import { createSlice } from "@reduxjs/toolkit";
import { broadcastsApi } from "./broadcastsApi";

const broadcastsSlice = createSlice({
    name: "broadcasts",
    initialState: {
        create: false,
        list: [],
        loading: false,
        selectedBroadcast: null,
    },
    reducers: {
        setSelectedBroadcast: (state, action) => {
            state.create = false;
            state.selectedBroadcast = action.payload;
        },
        toggleCreate: (state, action) => {
            state.create = action.payload;
            state.selectedBroadcast = null;
        },
        updateBroadcastDetails: (state, action) => {
            const { payload } = action;
            const { data } = payload;
            if (payload.event === "event://broadcast-update") {
                //state.list = payload.data;

                const broadcastIndex = state.list.findIndex((bc) => bc.id === data.broadcastId);
                // Update status of broadcast in the list
                if (data.type === "broadcast-status") {
                    state.list[broadcastIndex] = {
                        ...state.list[broadcastIndex],
                        status: data.status || state.list[broadcastIndex].status,
                    };
                }

                // updates for broadcast if it is selected (detail panel updates)
                if (state.selectedBroadcast?.id.toString() === data.broadcastId.toString()) {
                    if (data.type === "broadcast-status") {
                        state.selectedBroadcast.status = data.status;
                    }
                    state.selectedBroadcast.delivery_attempts =
                        data.deliveryAttempts || state.selectedBroadcast.delivery_attempts;
                    state.selectedBroadcast.delivery_success =
                        data.deliverySuccess || state.selectedBroadcast.delivery_success;
                    state.selectedBroadcast.delivery_failure =
                        data.deliveryFailure || state.selectedBroadcast.delivery_failure;
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(broadcastsApi.endpoints.createBroadcast.matchPending, (state) => {
                state.loading = true;
            })
            .addMatcher(broadcastsApi.endpoints.createBroadcast.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list.push(action.payload);
            })
            .addMatcher(broadcastsApi.endpoints.createBroadcast.matchRejected, (state) => {
                state.loading = false;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcast.matchPending, (state) => {
                state.loading = true;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcast.matchFulfilled, (state, action) => {
                state.loading = false;
                state.selectedBroadcast = action.payload.broadcast;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcast.matchRejected, (state) => {
                state.loading = false;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcasts.matchPending, (state, action) => {
                state.loading = true;
                state.list = action.payload.broadcasts;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcasts.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload.broadcasts;
            })
            .addMatcher(broadcastsApi.endpoints.getBroadcasts.matchRejected, (state) => {
                state.loading = false;
            })
            .addMatcher(broadcastsApi.endpoints.getAllBroadcasts.matchPending, (state) => {
                state.loading = true;
            })
            .addMatcher(broadcastsApi.endpoints.getAllBroadcasts.matchFulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addMatcher(broadcastsApi.endpoints.getAllBroadcasts.matchRejected, (state) => {
                state.loading = false;
            })
            .addMatcher(broadcastsApi.endpoints.updateBroadcast.matchPending, (state) => {
                state.loading = true;
            })
            .addMatcher(broadcastsApi.endpoints.updateBroadcast.matchFulfilled, (state, action) => {
                state.loading = false;
                state.selectedBroadcast = action.payload.broadcast;
            })
            .addMatcher(broadcastsApi.endpoints.updateBroadcast.matchRejected, (state) => {
                state.loading = false;
            });
    },
});

export default broadcastsSlice.reducer;

export const { setSelectedBroadcast, toggleCreate, updateBroadcastDetails } = broadcastsSlice.actions;
