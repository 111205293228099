import produce from "immer";

const initialState = {
    list: [],
    selected: null,
    automationStepModal: null,
};

const automationsReducer = (state = initialState, action) => {
    const { payload, type } = action;

    return produce(state, (draft) => {
        switch (type) {
            case "SET_AUTOMATIONS":
                draft.list = payload.automations;
                return draft;

            case "SET_SELECTED_AUTOMATION":
                draft.selected = state.list.find((automation) => automation.id === payload.id);
                return draft;

            case "UPDATE_AUTOMATION": {
                const automation = state.list.findIndex((automation) => automation.id === payload.id);

                if (automation >= 0) {
                    draft.list[automation] = { ...state.list[automation], ...payload.details };
                }

                if (state.selected?.id === payload.id) {
                    draft.selected = { ...state.selected, ...payload.details };
                }

                return draft;
            }
            case "UPDATE_SELECTED_AUTOMATION":
                draft.list = [payload.automation];

                draft.selected = draft.list[0];
                return draft;

            case "DELETE_AUTOMATION":
                draft.list = state.list.filter((automation) => automation.id !== payload.id);
                return draft;

            case "SHOW_AUTOMATION_STEP_MODAL":
                draft.automationStepModal = {
                    targetStep: payload.targetStep,
                };
                return draft;

            case "HIDE_AUTOMATION_STEP_MODAL":
                draft.automationStepModal = null;
                return draft;

            default:
                return draft;
        }
    });
};

export default automationsReducer;
