import { emptySplitApi as API } from "../api";

export const reminderTemplatesApi = API.injectEndpoints({
    endpoints: (builder) => ({
        getAllReminderTemplates: builder.query({
            query: () => "/account-integrations/reminder-templates",
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "ReminderTemplates", id })),
                          { type: "ReminderTemplates", id: "LIST" },
                      ]
                    : ["ReminderTemplates"],
            keepUnusedDataFor: 0,
        }),
        getReminderTemplate: builder.query({
            query: ({ accountIntegrationId, templateId }) => ({
                url: `/account-integrations/${accountIntegrationId}/reminder-templates/${templateId}`,
            }),
            providesTags: (result) => [{ type: "ReminderTemplates", id: result?.id }],
            // keepUnusedDataFor: 0,
        }),
        getReminderTemplates: builder.query({
            query: ({ accountIntegrationId }) => ({
                url: `/account-integrations/${accountIntegrationId}/reminder-templates`,
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "ReminderTemplates", id })),
                          { type: "ReminderTemplates", id: "LIST" },
                      ]
                    : ["ReminderTemplates"],
            // keepUnusedDataFor: 0,
        }),
        createReminderTemplate: builder.mutation({
            query: ({ integrationTypeId, name, description, items }) => ({
                url: `/account-integrations/${integrationTypeId}/reminder-templates`,
                method: "POST",
                body: {
                    name: name,
                    description: description,
                    items: items,
                },
            }),
            invalidatesTags: [{ type: "ReminderTemplates", id: "LIST" }],
        }),
        updateReminderTemplate: builder.mutation({
            query: ({ integrationTypeId, templateId, name, description, items }) => ({
                url: `/account-integrations/${integrationTypeId}/reminder-templates/${templateId}`,
                method: "PUT",
                body: {
                    name: name,
                    description: description,
                    items: items,
                },
            }),
            invalidatesTags: (result) => [{ type: "ReminderTemplates", id: result?.id }],
        }),
        deleteReminderTemplate: builder.mutation({
            query: ({ integrationTypeId, templateId }) => ({
                url: `/account-integrations/${integrationTypeId}/reminder-templates/${templateId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: "ReminderTemplates", id: result?.id }],
        }),
    }),
});

export const {
    useGetAllReminderTemplatesQuery,
    useGetReminderTemplatesQuery,
    useGetReminderTemplateQuery,
    useCreateReminderTemplateMutation,
    useUpdateReminderTemplateMutation,
    useDeleteReminderTemplateMutation,
} = reminderTemplatesApi;

// export const {
//     endpoints: {
//         getReminderTemplate,
//         createReminderTemplate,
//         updateReminderTemplate,
//     },
// } = reminderTemplatesApi;
