import * as uuid from "uuid";
import { SET_ALERT, REMOVE_ALERT } from "./types";

/**
 *
 * @param {string} msg The alert message
 * @param {string} alertType The alert type (success, error, info, warning)
 * @param {number} timeout The timeout for the alert to stay visible, in milliseconds
 * @returns
 */
export const setAlert =
    (msg, alertType, timeout = 3000) =>
    (dispatch) => {
        const id = uuid.v4();
        dispatch({
            type: SET_ALERT,
            payload: { msg, alertType, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
    };
