import produce from 'immer';
import {
    GET_MESSAGES_ERROR,
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    LOGOUT,
} from '../actions/types';

const initialState = {
    error: false,
    list: [],
    loading: false,
    pagination: {},
};

const messageReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case GET_MESSAGES_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case GET_MESSAGES_REQUEST:
                if (payload === 0) {
                    draft.list = [];
                    draft.pagination = {};
                }
                draft.error = false;
                draft.loading = true;
                return draft;
            case GET_MESSAGES_SUCCESS:
                draft.error = false;
                draft.list = payload.messages;
                draft.loading = false;
                draft.pagination = payload.pagination;
                return draft;
            default:
                return draft;
        }
    });
};

export default messageReducer;
