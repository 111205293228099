import React, { useEffect, useState } from 'react';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import roezanLogo from '../../assets/img/rz_logo_full.svg';
import { useHistory } from 'react-router-dom';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const AccountSetupLoader = () => {
    const history = useHistory();
    const [totalProgress, setTotalProgress] = useState(7);

    useEffect(() => {
        const amount = (Math.random() * 100) / 2;
        if (totalProgress === 100) {
            setTimeout(() => history.push('/verify'), 1000);
        } else if (totalProgress + amount >= 100) {
            setTotalProgress(100);
        } else {
            console.log(totalProgress, amount);
            setTimeout(
                () =>
                    setTotalProgress((progress) => progress + parseInt(amount)),
                1000
            );
        }
    }, [history, totalProgress, setTotalProgress]);
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{ textAlign: 'center' }}>
                <Box component='img' src={roezanLogo} alt='roezan' />
                <Typography component='h1' variant='h4'>
                    Setting Up Your Account
                </Typography>
                <br />
                <BorderLinearProgress
                    variant='determinate'
                    value={totalProgress}
                />
            </Box>
        </Box>
    );
};

export default AccountSetupLoader;
