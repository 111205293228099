import { emptySplitApi as API } from "../api";
import moment from "moment-timezone";

export const eventsApi = API.injectEndpoints({
    endpoints: (builder) => ({
        getEvent: builder.query({
            query: ({ accountIntegrationId, eventId }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}`,
            }),
            transformResponse: (response) => response.event,
            providesTags: (result) => [{ type: "Events", id: result?.id }],
            keepUnusedDataFor: 0,
        }),
        getAllEvents: builder.query({
            query: ({ futureOnly }) => ({
                url: `/account-integrations/events?future=${!!futureOnly}`,
            }),
            transformResponse: (response) => response.integrationEvents,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Events", id })), { type: "Events", id: "LIST" }]
                    : ["Events"],
            keepUnusedDataFor: 0,
        }),
        getEvents: builder.query({
            query: ({ accountIntegrationId }) => ({ url: `/account-integrations/${accountIntegrationId}/events` }),
            transformResponse: (response) => response.integrationEvents,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Events", id })), { type: "Events", id: "LIST" }]
                    : ["Events"],
            keepUnusedDataFor: 0,
        }),
        createEvent: builder.mutation({
            query: ({ integrationName, name, description, time, list, joinURL }) => ({
                url: "/account-integrations/custom/events?1=1",
                method: "POST",
                body: {
                    integrationName,
                    list,
                    data: { title: name, description, start_time: time, timezone: moment.tz.guess(), joinURL },
                },
            }),
            transformResponse: (response) => response.event,
            // providesTags: (_result) => [{ type: "Events", id: _result?.id }],
            invalidatesTags: [{ type: "Events", id: "LIST" }],
        }),
        updateEvent: builder.mutation({
            query: ({
                accountIntegrationId,
                eventId,
                event: { name, description, startTime, listId, joinURL, remindersActive },
            }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}`,
                method: "PUT",
                body: {
                    name: name,
                    description: description,
                    startTime: startTime,
                    listId: listId,
                    joinURL: joinURL,
                    remindersActive: remindersActive,
                },
            }),
            transformResponse: (response) => response.event,
            invalidatesTags: (result) => [{ type: "Events", id: result?.id }],
        }),
        deleteEvent: builder.mutation({
            query: ({ accountIntegrationId, eventId }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}`,
                method: "DELETE",
            }),
            transformResponse: (response) => response.eventId,
            invalidatesTags: (_result, _err, { eventId }) => [{ type: "Events", id: eventId }],
        }),
        getEventReminders: builder.query({
            query: ({ accountIntegrationId, eventId }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}/reminders`,
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "EventReminders", id })),
                          { type: "EventReminders", id: "LIST" },
                      ]
                    : ["EventReminders"],
            keepUnusedDataFor: 0,
        }),
        createEventReminder: builder.mutation({
            query: ({
                accountIntegrationId,
                eventId,
                reminder: {
                    relativeBefore,
                    relativeTimeAmount,
                    relativeTimeUnit,
                    message,
                    active,
                    media,
                    sendImmediately,
                },
            }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}/reminders`,
                method: "POST",
                body: {
                    relativeBefore: relativeBefore,
                    relativeTimeAmount: relativeTimeAmount,
                    relativeTimeUnit: relativeTimeUnit,
                    message: message,
                    active: active,
                    media: media,
                    sendImmediately: sendImmediately,
                },
            }),
            transformResponse: (response) => response.reminder,
            invalidatesTags: (_result, _err, { eventId }) => [{ type: "Events", id: eventId }],
        }),
        updateEventReminder: builder.mutation({
            query: ({
                accountIntegrationId,
                eventId,
                reminderId,
                reminder: {
                    relativeBefore,
                    relativeTimeAmount,
                    relativeTimeUnit,
                    message,
                    active,
                    media,
                    sendImmediately,
                },
            }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}/reminders/${reminderId}`,
                method: "PUT",
                body: {
                    relativeBefore: relativeBefore,
                    relativeTimeAmount: relativeTimeAmount,
                    relativeTimeUnit: relativeTimeUnit,
                    message: message,
                    active: active,
                    media: media,
                    sendImmediately: sendImmediately,
                },
            }),
            transformResponse: (response) => response.eventReminder,
            invalidatesTags: (_result, _err, { eventId }) => [{ type: "Events", id: eventId }],
        }),
        deleteEventReminder: builder.mutation({
            query: ({ accountIntegrationId, eventId, reminderId }) => ({
                url: `/account-integrations/${accountIntegrationId}/events/${eventId}/reminders/${reminderId}`,
                method: "DELETE",
            }),
            transformResponse: (response) => response.event,
            invalidatesTags: (_result, _err, { eventId }) => [{ type: "Events", id: eventId }],
        }),
        getEventTypes: builder.query({
            query: ({ accountIntegrationId, sync }) => ({
                url: `/account-integrations/${accountIntegrationId}/types?sync=${sync}`,
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "EventTypes", id })), { type: "EventTypes", id: "LIST" }]
                    : ["EventTypes"],
            keepUnusedDataFor: 0,
        }),
        updateEventType: builder.mutation({
            query: ({ accountIntegrationId, typeId, reminderTemplateId, active, remindersEnabled, optInQuestion }) => ({
                url: `/account-integrations/${accountIntegrationId}/types/${typeId}`,
                method: "PUT",
                body: {
                    reminderTemplateId: reminderTemplateId,
                    active: active,
                    remindersEnabled: remindersEnabled,
                    optInQuestion: optInQuestion,
                },
            }),
            // transformResponse: (response) => response.eventType,
            invalidatesTags: (_result, _err, { typeId }) => [{ type: "EventTypes", id: typeId }],
        }),
    }),
});

export const {
    useGetEventQuery,
    useGetAllEventsQuery,
    useGetEventsQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useGetEventRemindersQuery,
    useCreateEventReminderMutation,
    useUpdateEventReminderMutation,
    useDeleteEventReminderMutation,
    useGetEventTypesQuery,
    useUpdateEventTypeMutation,
} = eventsApi;

// export const {
//     endpoints: {
//         getEvents,
//         createEvent,
//         updateEvent,
//         deleteEvent,
//         getEventReminders,
//         createEventReminder,
//         updateEventReminder,
//         deleteEventReminder,
//     },
// } = eventsApi;
