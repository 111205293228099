import React, { createContext } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import * as uuid from "uuid";
import {
    //setSelectedConversation,
    updateConversationThread,
} from "./actions/conversations";
import { updateBroadcastDetails } from "./app/services/broadcasts/broadcastsSlice";

const STATUS_PENDING = "pending";

const EVENT_GET_MESSAGE = "event://get-message";
const EVENT_SEND_MESSAGE = "event://send-message";
const EVENT_SEND_MESSAGE_ACK = "event://send-message-ack";
const EVENT_SEND_MESSAGE_STATUS = "event://send-message-status";
const EVENT_BROADCAST_UPDATE = "event://broadcast-update";

const EVENT_CONNECT_BROADCASTS = "event://connect-broadcasts";

const WebSocketContext = createContext(null);

export { WebSocketContext, EVENT_GET_MESSAGE, EVENT_SEND_MESSAGE, EVENT_SEND_MESSAGE_ACK, EVENT_SEND_MESSAGE_STATUS };

const WebSocketProvider = ({ children }) => {
    let socket;
    let ws;
    const host = process.env.REACT_APP_WS_BASE || "http://localhost:3009/";

    const dispatch = useDispatch();

    const connectSocket = () => {
        socket = io.connect(host, {
            query: { token: localStorage.roezan },
        });

        socket.on("connect", () => {
            console.log("Socket Connect Successful!");
        });

        socket.on("error", (error) => {
            console.error("Socket Error: ", error);
        });

        socket.on("connect_error", (error) => {
            console.error("Socket Connect Error: ", error);
        });

        socket.on("event://roezan", (data) => {
            console.log("ROEZAN SOCKETS", data);
        });

        socket.on(EVENT_GET_MESSAGE, (msg) => {
            dispatch(updateConversationThread(EVENT_GET_MESSAGE, msg));
        });

        socket.on(EVENT_SEND_MESSAGE_ACK, (msg) => {
            dispatch(updateConversationThread(EVENT_SEND_MESSAGE_ACK, msg));
        });

        socket.on(EVENT_SEND_MESSAGE_STATUS, (msg) => {
            dispatch(updateConversationThread(EVENT_SEND_MESSAGE_STATUS, msg));
        });

        socket.on(EVENT_BROADCAST_UPDATE, (msg) => {
            console.log("BROADCAST UPDATE", msg);
            dispatch(updateBroadcastDetails({ event: EVENT_BROADCAST_UPDATE, data: msg }));
        });

        ws = {
            socket: socket,
            connectBroadcasts,
            selectConversation,
            sendMessage,
        };
    };

    const connectBroadcasts = () => {
        if (!socket.connected || !socket.query.token || socket.query.token.length === 0) {
            connectSocket();
        }
        socket.emit(EVENT_CONNECT_BROADCASTS);
    };

    const selectConversation = (convo) => {
        if (!socket.connected || !socket.query.token || socket.query.token.length === 0) {
            connectSocket();
        }
        const payload = { convo };
        socket.emit("event://select-conversation", payload);
    };

    const sendMessage = (
        accountId,
        accountPhoneId,
        contactId,
        conversationId,
        message,
        phone,
        messageType = "sms",
        media = [],
        statusId
    ) => {
        if (!socket.connected || !socket.query.token || socket.query.token.length === 0) {
            connectSocket();
        }
        const payload = {
            accountId: accountId,
            accountPhoneId: accountPhoneId,
            contactId: contactId,
            conversationId: conversationId,
            phoneNumber: phone,
            id: uuid.v1(),
            content: message,
            direction: "outbound",
            messageType: messageType,
            media: media,
            status: STATUS_PENDING,
            statusId: statusId,
        };
        socket.emit(EVENT_SEND_MESSAGE, JSON.stringify(payload));

        dispatch(updateConversationThread(EVENT_SEND_MESSAGE, payload));
    };

    if (!socket) {
        connectSocket();
    }

    return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;
