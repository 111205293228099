import produce from 'immer';
import { SET_DASH_VIEW_HEIGHT, SET_NAV_HEIGHT } from '../actions/types';

const initialState = {
    navHeight: 84,
    dashViewHeight: 1,
};

const accountReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case SET_NAV_HEIGHT:
                draft.navHeight = payload;
                return draft;
            case SET_DASH_VIEW_HEIGHT:
                draft.dashViewHeight = payload;
                return draft;
            default:
                return draft;
        }
    });
};

export default accountReducer;
