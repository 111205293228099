import produce from 'immer';
import {
    CREATE_INTEGRATIONS_ERROR,
    CREATE_INTEGRATIONS_REQUEST,
    CREATE_INTEGRATIONS_SUCCESS,
    DELETE_INTEGRATIONS_ERROR,
    DELETE_INTEGRATIONS_REQUEST,
    DELETE_INTEGRATIONS_SUCCESS,
    GET_INTEGRATIONS_ERROR,
    GET_INTEGRATIONS_REQUEST,
    GET_INTEGRATIONS_SUCCESS,
} from '../actions/types';

const initialState = {
    error: false,
    list: [],
    loading: false,
};

const integrationsReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case CREATE_INTEGRATIONS_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case CREATE_INTEGRATIONS_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case CREATE_INTEGRATIONS_SUCCESS:
                draft.error = false;
                draft.loading = false;
                if (draft.list) {
                    draft.list.push(payload.integration);
                } else {
                    draft.list = [payload.integration];
                }
                return draft;
            case DELETE_INTEGRATIONS_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case DELETE_INTEGRATIONS_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case DELETE_INTEGRATIONS_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload)
                );
                return draft;
            case GET_INTEGRATIONS_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case GET_INTEGRATIONS_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case GET_INTEGRATIONS_SUCCESS:
                draft.error = false;
                draft.list = payload.integrations;
                draft.loading = false;
                return draft;

            default:
                return draft;
        }
    });
};

export default integrationsReducer;
