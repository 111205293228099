import produce from "immer";
import {
    GET_ACCOUNT_PHONES_ERROR,
    GET_ACCOUNT_PHONES_REQUEST,
    GET_ACCOUNT_PHONES_SUCCESS,
    RELEASE_PHONE_ERROR,
    RELEASE_PHONE_REQUEST,
    RELEASE_PHONE_SUCCESS,
    RESERVE_PHONE_ERROR,
    RESERVE_PHONE_REQUEST,
    RESERVE_PHONE_SUCCESS,
} from "../actions/types";

const initialState = {
    list: null,
    loading: false,
};

const accountPhonesReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case GET_ACCOUNT_PHONES_ERROR:
                draft.loading = false;
                return draft;
            case GET_ACCOUNT_PHONES_REQUEST:
                draft.loading = true;
                return draft;
            case GET_ACCOUNT_PHONES_SUCCESS:
                draft.list = payload;
                draft.loading = false;
                return draft;
            case RELEASE_PHONE_ERROR:
                draft.loading = false;
                return draft;
            case RELEASE_PHONE_REQUEST:
                draft.loading = true;
                return draft;
            case RELEASE_PHONE_SUCCESS:
                draft.loading = false;
                draft.list = draft.list.filter((phone) => phone.id !== payload);
                return draft;
            case RESERVE_PHONE_ERROR:
                draft.loading = false;
                draft.error = true;
                return draft;
            case RESERVE_PHONE_REQUEST:
                draft.loading = true;
                draft.error = false;
                return draft;
            case RESERVE_PHONE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.list.push(payload);
                return draft;
            default:
                return draft;
        }
    });
};

export default accountPhonesReducer;
