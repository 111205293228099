import { emptySplitApi as API } from "../api";

export const broadcastsApi = API.injectEndpoints({
    endpoints: (builder) => ({
        createBroadcast: builder.mutation({
            query: ({ broadcastName, scheduledTime, listIds, segmentId, message, media, immediately, draft }) => ({
                url: "/broadcasts",
                method: "POST",
                body: {
                    broadcastName,
                    scheduledTime,
                    listIds,
                    segmentId,
                    message,
                    media,
                    immediately,
                    draft,
                },
            }),
            invalidatesTags: [{ type: "Broadcasts", id: "LIST" }],
        }),
        getBroadcast: builder.query({
            query: ({ id }) => ({
                url: `/broadcasts/${id}`,
            }),
            transformResponse: (response) => response.broadcast,
            providesTags: (result) => [{ type: "Broadcasts", id: result?.id }],
            keepUnusedDataFor: 0,
        }),
        getBroadcasts: builder.query({
            query: ({ sortBy, page, pageSize }) => ({
                url: `/broadcasts`,
                params: { sort_by: sortBy, page, pageSize },
            }),
            // transformResponse: (response) => response.broadcasts,
            invalidatesTags: ["Broadcasts"],
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Broadcasts", id })), { type: "Broadcasts", id: "LIST" }]
                    : ["Broadcasts"],
            keepUnusedDataFor: 0,
        }),
        getAllBroadcasts: builder.query({
            query: ({ sortBy }) => ({
                url: `/broadcasts/all`,
                params: { sort_by: sortBy },
            }),
            transformResponse: (response) => response.broadcasts,
            invalidatesTags: ["Broadcasts"],
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Broadcasts", id })), { type: "Broadcasts", id: "LIST" }]
                    : ["Broadcasts"],
            keepUnusedDataFor: 0,
        }),
        updateBroadcast: builder.mutation({
            query: ({ broadcastId, listIds, name, message, media, deliverySchedule, segmentId, draft, cancel }) => ({
                url: `/broadcasts/${broadcastId}`,
                method: "PUT",
                body: { listIds, name, message, media, deliverySchedule, segmentId, draft, cancel },
            }),
            invalidatesTags: (result, err, { broadcastId }) => [{ type: "Broadcasts", id: broadcastId }],
        }),
        cancelBroadcast: builder.mutation({
            query: ({ broadcastId }) => ({
                url: `/broadcasts/${broadcastId}/cancel`,
                method: "POST",
            }),
            invalidatesTags: (result, err, { broadcastId }) => [{ type: "Broadcasts", id: broadcastId }],
        }),
        previewRecipients: builder.query({
            query: ({ listIds, segmentId }) => ({
                url: `/broadcasts/preview-recipients`,
                params: { listIds, segmentId, page: 0, size: 10 },
            }),
            providesTags: (result) => (result ? [{ type: "Broadcasts", id: "PREVIEW" }] : []),
        }),
    }),
});

export const {
    useCreateBroadcastMutation,
    useGetBroadcastQuery,
    useGetBroadcastsQuery,
    useGetAllBroadcastsQuery,
    useUpdateBroadcastMutation,
    useCancelBroadcastMutation,
    usePreviewRecipientsQuery,
} = broadcastsApi;
