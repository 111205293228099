import produce from "immer";
import {
    ARCHIVE_CONVERSATION_ERROR,
    ARCHIVE_CONVERSATION_REQUEST,
    ARCHIVE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    CREATE_CONVERSATION_REQUEST,
    CREATE_CONVERSATION_SUCCESS,
    CLEAR_CURRENT_CONVERSATION,
    CREATE_DRAFT_CONVERSATION,
    DELETE_DRAFT_CONVERSATION,
    GET_CONVERSATION_LIST_ERROR,
    GET_CONVERSATION_LIST_REQUEST,
    GET_CONVERSATION_LIST_SUCCESS,
    LOGOUT,
    OPTIN_CONTACT_SUCCESS,
    OPTOUT_CONTACT_SUCCESS,
    SELECT_DRAFT_CONTACT,
    SET_CURRENT_CONVERSATION,
    UPDATE_CONVERSATION_THREAD,
    UPDATE_CONVERSATION_SUCCESS,
    UPDATE_CONTACT_SUCCESS,
    GET_ALL_CONVERSATION_LIST_REQUEST,
    GET_ALL_CONVERSATION_LIST_ERROR,
    GET_ALL_CONVERSATION_LIST_SUCCESS,
    SET_STATUS_ID,
} from "../actions/types";

//TODO Update DraftConvo to be an array of Drafts
// 		and perhaps update Conversation objects to have
//		a Type property (['new', 'existing'] ?)
const initialState = {
    archivedConvo: {},
    currentConvo: {},
    draftedMessage: "",
    queriedContactId: null,
    draftToDelete: {},
    drafting: false,
    draftContact: {},
    draftConvo: {},
    latestEvent: "",
    latestMessage: {},
    list: [],
    listPagination: {},
    loading: false,
    messages: [],
    messagesPagination: {},
    statuses: [],
    statusId: 1,
};

const conversationReducer = (state = initialState, action) => {
    const { payload, type } = action;
    let searchConvo;
    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case ARCHIVE_CONVERSATION_ERROR:
                draft.archivedConvo = {};
                draft.loading = false;
                return draft;

            case ARCHIVE_CONVERSATION_REQUEST:
                draft.archivedConvo = {};
                draft.currentConvo = {};
                draft.loading = true;
                return draft;

            case ARCHIVE_CONVERSATION_SUCCESS:
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.conversation.id),
                    1
                );
                draft.loading = false;
                return draft;

            case CREATE_CONVERSATION_REQUEST:
                draft.loading = true;
                draft.error = false;
                return draft;

            case CREATE_CONVERSATION_ERROR:
                draft.error = true;
                draft.errorMessage = payload.message;
                draft.loading = false;
                draft.currentConvo = {};
                return draft;

            case CREATE_CONVERSATION_SUCCESS:
                draft.currentConvo = payload.convo.conversation;
                draft.statusId = payload.convo.conversation.status_id;
                draft.drafting = false;
                draft.loading = false;
                return draft;

            case CLEAR_CURRENT_CONVERSATION:
                draft.currentConvo = {};
                draft.draftedMessage = "";
                draft.drafting = false;
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.loading = false;
                draft.messages = [];
                draft.messagesPagination = {};
                return draft;

            case CREATE_DRAFT_CONVERSATION:
                draft.statusId = 2; // Show drafts in Pending page
                draft.currentConvo = payload;
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list = [payload].concat(draft.list); // UI Code listens for List change and appends or prepends to this array
                draft.draftConvo = payload;
                draft.drafting = true;
                draft.draftToDelete = {};
                draft.messages = [];
                draft.messagesPagination = {};
                return draft;

            case DELETE_DRAFT_CONVERSATION:
                draft.currentConvo = {};
                draft.drafting = false;
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1
                );
                return draft;

            case GET_ALL_CONVERSATION_LIST_REQUEST:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.loading = true;
                return draft;
            case GET_ALL_CONVERSATION_LIST_ERROR:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.loading = false;
                return draft;
            case GET_ALL_CONVERSATION_LIST_SUCCESS:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list = payload.list;
                draft.loading = false;
                searchConvo = payload.contactId && payload.list.filter((c) => c.contact_id === payload.contactId);
                draft.currentConvo = searchConvo && searchConvo.length > 0 ? searchConvo[0] : draft.currentConvo;
                draft.queriedContactId = payload.contactId;
                return draft;

            case GET_CONVERSATION_LIST_REQUEST:
                if (payload === 0) {
                    // We're requesting the first (index 0) page of conversations, clear out the old List and Pagination data
                    draft.listPagination = {};
                    draft.list = draft.drafting ? [draft.draftConvo] : [];
                }
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.loading = true;
                return draft;

            case GET_CONVERSATION_LIST_ERROR:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list = [];
                draft.loading = false;
                draft.listPagination = {};
                return draft;

            case GET_CONVERSATION_LIST_SUCCESS:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list = draft.drafting ? [draft.draftConvo].concat(payload.list) : payload.list;
                draft.listPagination = payload.pagination;
                draft.loading = false;
                searchConvo = payload.contactId && payload.list.filter((c) => c.contact_id === payload.contactId);
                draft.currentConvo = searchConvo && searchConvo.length > 0 ? searchConvo[0] : draft.currentConvo;
                draft.queriedContactId = payload.contactId;
                draft.statuses = payload.statuses;
                return draft;

            case OPTIN_CONTACT_SUCCESS:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list.splice(
                    draft.list.findIndex((li) => li.contact.id === payload.contact.id),
                    1,
                    payload
                );
                return draft;
            case OPTOUT_CONTACT_SUCCESS:
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.list.splice(
                    draft.list.findIndex((li) => li.contact.id === payload.contact.id),
                    1,
                    payload
                );
                return draft;
            case SELECT_DRAFT_CONTACT:
                draft.currentConvo = payload.conversation ? payload.conversation : draft.currentConvo;
                if (payload.conversation && draft.list.filter((l) => l.id === payload.conversation.id).length === 0) {
                    draft.list.splice(0, 1, payload.conversation);
                } else {
                    draft.list.shift();
                }

                if (!payload.conversation) {
                    // Append selected contact to the current draft convo (e.g. { id: -001 })
                    //      => { id: -001, contact: {...} }
                    draft.currentConvo.contact = payload.contact;
                } else {
                    draft.draftToDelete = draft.draftConvo;
                    draft.draftConvo = {};
                }
                draft.draftContact = payload.contact || {};
                draft.drafting = payload.conversation ? false : true;
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.queriedContactId = null;
                return draft;

            case SET_CURRENT_CONVERSATION:
                draft.currentConvo = payload;
                draft.drafting = action.drafting;
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.queriedContactId = null;
                // Remove draft convo from list if we clicked another conversation and indicate that we're not drafting
                draft.list = action.drafting ? draft.list : draft.list.filter((li) => li.id > 0);
                return draft;

            case "SET_CURRENT_CONVERSATION_MESSAGE":
                draft.draftedMessage = payload;
                return draft;

            case SET_STATUS_ID:
                // draft.list = draft.list.filter((li) => li.id > 0);
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.statusId = payload;
                return draft;

            case UPDATE_CONTACT_SUCCESS: {
                draft.latestEvent = "";
                draft.latestMessage = {};
                const index = draft.list.findIndex((li) => li.contact.id === payload.contact.id);
                if (index > -1) {
                    draft.list[index].contact = payload.contact;
                }
                return draft;
            }
            case UPDATE_CONVERSATION_SUCCESS:
                if (payload.status_id !== 3) {
                    draft.currentConvo = payload;
                }
                if (draft.statusId === payload.status_id) {
                    draft.list.splice(
                        draft.list.findIndex((li) => li?.contact_id === payload?.contact_id),
                        1,
                        payload
                    );
                } else {
                    draft.list.splice(
                        draft.list.findIndex((li) => li?.contact_id === payload?.contact_id),
                        1
                    );
                }
                draft.drafting = false;
                draft.draftConvo = {};
                draft.latestEvent = "";
                draft.latestMessage = {};
                draft.loading = false;
                return draft;

            case UPDATE_CONVERSATION_THREAD: {
                // Websocket message updates are passed through here
                draft.latestEvent = action.event;
                draft.latestMessage = payload;
                const relevantConversationIndex = draft.list?.findIndex((li) => li.contact_id === payload.contact_id);
                if (relevantConversationIndex >= 0) {
                    draft.list[relevantConversationIndex].lastMessage = payload;
                }
                return draft;
            }

            case "EXIT_CONVERSATIONS_PAGE":
                return initialState;

            default:
                return draft;
        }
    });
};

export default conversationReducer;
