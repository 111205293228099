const {
    white,
    offWhite,
    iceberg,
    blueChill,
    tiber,
    flamingo,
    cosmos,
    springwood,
    text,
    weight,
} = require("../assets/jss/theme");

// eslint-disable-next-line no-unused-vars
const getDesignTokens = (mode) => ({
    components: {
        MuiAlert: {
            variants: [
                {
                    props: { variant: "filledError" },
                    style: {
                        backgroundColor: cosmos,
                        color: flamingo,
                    },
                },
            ],
            styleOverrides: {
                filledError: {
                    backgroundColor: cosmos,
                    color: flamingo,
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {},
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "navigation" },
                    style: {
                        "@media screen and (max-width: 900px)": {
                            display: "flex",
                            margin: "10px 15px 0",
                            width: "-webkit-fill-available",
                            "& svg": {
                                width: "24px",
                                height: "30px",
                                marginRight: "15px",
                                marginLeft: "-15px",
                            },
                            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                                fontSize: "24px",
                                lineHeight: "30px",
                                width: "24px",
                                height: "30px",
                                marginRight: "15px",
                                marginLeft: "-15px",
                            },
                            "& > span > span": {
                                justifyContent: "flex-start",
                                //width: '100%',
                                marginRight: "15px",
                            },
                            "&:hover": {
                                backgroundColor: tiber[30],
                            },
                        },
                    },
                },
                {
                    props: { justicon: "true" },
                    style: {
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        fontSize: "20px",
                        height: "41px",
                        minWidth: "41px",
                        width: "41px",
                        "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
                            marginRight: "0px",
                        },
                    },
                },
                {
                    props: { variant: "text", color: "primary" },
                    style: {
                        backgroundColor: "transparent !important",
                        color: tiber[100],
                        "&:hover": {
                            backgroundColor: "transparent !important",
                        },
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        backgroundColor: "transparent",
                        color: white,
                        border: `1px solid ${white}`,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                        "&:disabled": {
                            border: `1px solid ${offWhite}`,
                            color: offWhite,
                        },
                    },
                },
                {
                    props: { color: "primary" },
                    style: {
                        backgroundColor: tiber[100],
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[70],
                        },
                    },
                },
                {
                    props: { color: "secondary" },
                    style: {
                        backgroundColor: tiber[5],
                        border: `1px solid ${tiber[10]}`,
                        color: tiber[100],
                        "&:hover": {
                            backgroundColor: tiber[30],
                        },
                    },
                },
                {
                    props: { color: "error" },
                    style: {
                        backgroundColor: flamingo,
                        color: white,
                        "&:hover": {
                            backgroundColor: flamingo + "50",
                        },
                    },
                },
                {
                    props: { color: "warning" },
                    style: {
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[100],
                        },
                    },
                },
                {
                    props: { color: "danger" },
                    style: {
                        backgroundColor: cosmos,
                        color: flamingo,
                        "&:hover": {
                            backgroundColor: "#ffefef",
                        },
                    },
                },
                {
                    props: { color: "tiber" },
                    style: {
                        backgroundColor: tiber[100],
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[70],
                        },
                    },
                },
                {
                    props: { color: "blueChill" },
                    style: {
                        backgroundColor: blueChill,
                        color: white,
                    },
                },
                {
                    props: { color: "transparent" },
                    style: {
                        backgroundColor: "transparent",
                        "&:hover": {
                            backgroundColor: tiber[10],
                        },
                    },
                },
                {
                    props: { variant: "offWhite" },
                    style: {
                        backgroundColor: tiber[5],
                        border: `1px solid ${tiber[10]}`,
                        color: "primary.dark",
                        "&:hover": {
                            backgroundColor: tiber[10],
                            color: "white",
                        },
                    },
                },
                {
                    props: { variant: "outlined", color: "tiber10" },
                    style: {
                        backgroundColor: tiber[10],
                        "&:hover": {
                            backgroundColor: tiber[30],
                        },
                    },
                },
                {
                    props: { variant: "text", size: "sm" },
                    style: {
                        height: "30px",
                        minWidth: "30px",
                        width: "30px",
                        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                            fontSize: "17px",
                            lineHeight: "29px",
                        },
                        "& svg": {
                            width: "17px",
                            height: "17px",
                        },
                    },
                },
            ],
            defaultProps: {
                disableElevation: true,
                disableFocusRipple: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    ...text["text-sm"],
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    fontStyle: "normal",
                    gap: "8px",
                    height: "40px",
                    fontFamily: "GeneralSans-Variable",
                    fontWeight: weight.semibold,
                    justifyContent: "center",
                    minWidth: "fit-content",
                    padding: "10px 16px",
                    textTransform: "capitalize !important",
                    "&:hover": {
                        backgroundColor: tiber[70],
                    },
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    border: `2px solid ${tiber[100]}`,
                    borderRadius: "8px",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "0",
                    "&:last-child": {
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    alignContent: "center",
                    backgroundColor: tiber[100],
                    color: white,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    minHeight: "66px",
                    padding: "20px",
                    wordBreak: "normal !important",
                    gap: "12px",
                    "& .MuiCardHeader-action": {
                        display: "flex",
                        flexDirection: "row",
                        gap: "12px",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: tiber[70],
                    ...text["text-sm"],
                },
            },
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: "success" },
                    style: {
                        color: `${blueChill}`,
                        backgroundColor: `${iceberg}`,
                    },
                },
                {
                    props: { variant: "fail" },
                    style: {
                        color: `${flamingo}`,
                        backgroundColor: `${cosmos}`,
                    },
                },
            ],
            styleOverrides: {
                label: ({ ownerState }) => ({
                    ...{
                        backgroundColor: ownerState.customColor,
                        padding: "0",
                        ...text["text-xs"],
                        fontWeight: weight.semibold,
                    },
                }),
                deleteIcon: {
                    margin: 0,
                },
                avatar: {
                    marginLeft: 0,
                    marginRight: 0,
                },
                icon: {
                    marginLeft: 0,
                    marginRight: 0,
                },
                root: {
                    alignItems: "center",
                    backgroundColor: tiber[10],
                    borderRadius: "12px",
                    color: tiber[70],
                    display: "flex",
                    flexDirection: "row",
                    gap: "2px",
                    height: "fit-content",
                    padding: "2px 8px",
                    width: "fit-content",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                backdrop: {
                    backgroundColor: tiber[100],
                    opacity: "0.6 !important",
                },
                paper: {
                    border: `2px solid ${tiber[100]}`,
                    borderRadius: "8px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "16px !important",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    alignItems: "center",
                    backgroundColor: tiber[100],
                    color: white,
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: weight.semibold,
                    gap: "12px",
                    padding: { sx: "16px", sm: "20px" },
                    ...text["text-xl"],
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    backgroundColor: tiber[100],
                },
                "&-paper": {
                    backgroundColor: `${tiber[100]} !important`,
                },
            },
        },
        MuiIconButton: {
            variants: [
                {
                    props: { color: "primary" },
                    style: {
                        backgroundColor: tiber[100],
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[70],
                        },
                    },
                },
                {
                    props: { color: "secondary" },
                    style: {
                        // backgroundColor: tiber[5],
                        // border: `1px solid ${tiber[10]}`,
                        color: tiber[100],
                        "&:hover": {
                            backgroundColor: tiber[30],
                        },
                    },
                },
                {
                    props: { color: "danger" },
                    style: {
                        backgroundColor: cosmos,
                        color: flamingo,
                        "&:hover": {
                            backgroundColor: "#ffefef",
                        },
                    },
                },
                {
                    props: { color: "tiber" },
                    style: {
                        backgroundColor: tiber[100],
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[70],
                        },
                    },
                },
                {
                    props: { color: "blueChill" },
                    style: {
                        backgroundColor: blueChill,
                        color: white,
                        "&:hover": {
                            backgroundColor: tiber[70],
                        },
                    },
                },
                {
                    props: { variant: "text" },
                    style: {
                        backgroundColor: "transparent",
                    },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: tiber[70],
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
                notchedOutline: {
                    border: `1px solid ${tiber[100]}`,
                },
                "& input": {
                    alignItems: "center",
                    border: `1px solid ${tiber[100]}`,
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    height: "44px",
                    padding: "10px 14px !important",
                    ...text["text-md"],
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: "6px",
                    color: tiber[70],
                    ...text["text-sm"],
                    "&.Mui-selected": {
                        backgroundColor: tiber[100],
                        color: white,
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {},
        },
        MuiSelect: {
            styleOverrides: {},
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& th": {
                        color: tiber[70],
                        ...text["text-xs"],
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    ...text["text-sm"],
                    fontFamily: "GeneralSans-Variable",
                    fontWeight: weight.semibold,
                    padding: "10px 16px",
                    textTransform: "capitalize !important",
                    "&:hover": {
                        backgroundColor: tiber[70],
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            variants: [
                {
                    props: { variant: "light" },
                    style: {
                        backgroundColor: tiber[100],
                        borderRadius: "8px",
                        "& button": {
                            border: `1px solid ${tiber[5]}`,
                            color: white,
                        },
                        "& button.Mui-selected": {
                            backgroundColor: blueChill,
                        },
                    },
                },
            ],
            styleOverrides: {
                root: { textTransform: "normal !important" },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: "display-sm" },
                    style: {
                        ...text["display-sm"],
                    },
                },
                {
                    props: { variant: "display-xs" },
                    style: {
                        ...text["display-xs"],
                    },
                },
                {
                    props: { variant: "text-xl" },
                    style: {
                        ...text["text-xl"],
                    },
                },
                // {
                //     props: { variant: 'text-lg' },
                //     style: {
                //         fontSize: '1.5rem',
                //         lineHeight: '2rem',
                //     },
                // },
                {
                    props: { variant: "text-md" },
                    style: {
                        ...text["text-md"],
                    },
                },
                {
                    props: { variant: "text-sm" },
                    style: {
                        ...text["text-sm"],
                    },
                },
                {
                    props: { variant: "text-xs" },
                    style: {
                        ...text["text-xs"],
                    },
                },
                {
                    props: { weight: "bold" },
                    style: {
                        // fontWeight: weight.bold,
                        fontFamily: "GeneralSans-Bold",
                    },
                },
                {
                    props: { weight: "semibold" },
                    style: {
                        // fontWeight: weight.semibold,
                        fontFamily: "GeneralSans-Semibold",
                    },
                },
                {
                    props: { variant: "hg" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "72px",
                        lineHeight: "78px",
                        /* identical to box height, or 108% */
                        letterSpacing: "-0.01em",
                    },
                },
                {
                    props: { variant: "h1" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "54px",
                        lineHeight: "60px",
                        /* identical to box height, or 111% */
                        letterSpacing: "-0.01em",
                    },
                },
                {
                    props: { variant: "h2" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "42px",
                        lineHeight: "48px",
                        /* identical to box height, or 114% */
                        letterSpacing: "-0.01em",
                    },
                },
                {
                    props: { variant: "h3" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "32px",
                        lineHeight: "40px",
                        /* identical to box height, or 125% */
                        letterSpacing: "-0.005em",
                    },
                },
                {
                    props: { variant: "h4" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "24px",
                        lineHeight: "32px",
                        /* identical to box height, or 133% */
                        letterSpacing: "-0.005em",
                    },
                },
                {
                    props: { variant: "h5" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "20px",
                        lineHeight: "28px",
                        /* identical to box height, or 140% */
                    },
                },
                {
                    props: { variant: "h6" },
                    style: {
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "24px",
                        /* identical to box height, or 150% */
                    },
                },
            ],
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
            },
        },
    },
    palette: {
        primary: {
            light: iceberg,
            main: blueChill,
            dark: tiber[100],
        },
        secondary: {
            light: cosmos,
            main: flamingo,
            dark: "#5E0808",
        },
        danger: {
            main: flamingo,
            light: cosmos,
        },
        tiber: {
            main: tiber[100],
            ...tiber,
        },
        blueChill: {
            main: blueChill,
        },
        iceberg: {
            main: iceberg,
        },
        white: {
            main: white,
        },
        springwood: {
            main: springwood,
        },
    },
    typography: {
        fontFamily: "GeneralSans-Medium",
        // fontWeight: '300',
        // lineHeight: '1.5em',
        wordBreak: "normal !important",
    },
});

export default getDesignTokens;
