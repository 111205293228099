import produce from "immer";
import {
    DELETE_SEGMENT_ERROR,
    DELETE_SEGMENT_REQUEST,
    DELETE_SEGMENT_SUCCESS,
    GET_SEGMENTS_ERROR,
    GET_SEGMENTS_REQUEST,
    GET_SEGMENTS_SUCCESS,
    UPDATE_SEGMENT_ERROR,
    UPDATE_SEGMENT_REQUEST,
    UPDATE_SEGMENT_SUCCESS,
} from "../actions/types";

const initialState = {
    error: false,
    list: [],
    loading: false,
    pagination: {},
    selectedSegment: null,
};

const segmentReducer = (state = initialState, action) => {
    const { payload, type } = action;
    return produce(state, (draft) => {
        switch (type) {
            case DELETE_SEGMENT_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case DELETE_SEGMENT_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case DELETE_SEGMENT_SUCCESS:
                draft.list.splice(
                    draft.list.findIndex((seg) => seg.id === payload.id),
                    1
                );
                draft.loading = false;
                draft.error = false;
                return draft;

            case GET_SEGMENTS_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case GET_SEGMENTS_REQUEST:
                if (payload === 0) {
                    draft.list = [];
                    draft.pagination = {};
                }
                draft.error = false;
                draft.loading = true;
                return draft;
            case GET_SEGMENTS_SUCCESS:
                draft.error = false;
                draft.list = payload.segments;
                draft.loading = false;
                draft.pagination = payload.pagination;
                return draft;
            case "SELECT_SEGMENT":
                draft.selectedSegment = payload;
                return draft;
            case UPDATE_SEGMENT_ERROR:
                draft.error = true;
                draft.loading = false;
                return draft;
            case UPDATE_SEGMENT_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case UPDATE_SEGMENT_SUCCESS:
                draft.list.splice(
                    draft.list.findIndex((seg) => seg.id === payload.id),
                    1,
                    payload
                );
                draft.selectedSegment = payload;
                draft.loading = false;
                draft.error = false;
                return draft;
            default:
                return draft;
        }
    });
};

export default segmentReducer;
