import produce from "immer";
import {
    CREATE_ACCOUNTINTEGRATION_SUCCESS,
    GET_ACCOUNTINTEGRATIONS_ERROR,
    GET_ACCOUNTINTEGRATIONS_REQUEST,
    GET_ACCOUNTINTEGRATIONS_SUCCESS,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_SUCCESS,
    LOGOUT,
    REMOVE_ACCOUNTINTEGRATIONS_ERROR,
    REMOVE_ACCOUNTINTEGRATIONS_REQUEST,
    REMOVE_ACCOUNTINTEGRATIONS_SUCCESS,
    SYNC_ACCOUNTINTEGRATION_SUCCESS,
    SELECT_ACCOUNT_PHONE,
    UPDATE_ACCOUNT_ERROR,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    RESERVE_PHONE_ERROR,
    RESERVE_PHONE_REQUEST,
    RESERVE_PHONE_SUCCESS,
    CREATE_EVENT_SUCCESS,
} from "../actions/types";

const initialState = {
    loading: false,
    error: null,
    contactPhone: "",
    billing: null,
    email: "",
    events: null,
    friendlyName: "",
    id: null,
    integrations: null,
    invoices: null,
    optinBypass: false,
    optinMessage: "",
    optinMessageConfirmation: "",
    phones: null,
    plan: null,
    planId: null,
    selectedAccountPhone: null,
    tags: null,
    usages: null,
    users: null,
    uuid: null,
    verified: false,
    // registration: {
    //     accountId: '',
    //     customerId: '',
    //     email: '',
    //     friendlyName: '',
    // },
    superAdmin: false,
    verificationComplete: false,
};

const accountReducer = (state = initialState, action) => {
    const { payload, type } = action;
    let integrationIndex;

    return produce(state, (draft) => {
        switch (type) {
            case LOGOUT:
                return initialState;
            case GET_ACCOUNT_REQUEST:
                draft.loading = true;
                return draft;
            case GET_ACCOUNT_SUCCESS:
                draft.loading = false;
                draft.email = payload.account.contact_email;
                draft.optinBypass = payload.account.double_optin_bypass;
                draft.optinMessage = payload.account.optin_message;
                draft.optinMessageConfirmation = payload.account.optin_message_confirmation;
                draft.friendlyName = payload.account.friendly_name;
                draft.id = payload.account.id;
                draft.integrations = payload.account.integrations;
                draft.invoices = payload.account.invoices;
                draft.planId = payload.account.plan_id;
                draft.billing = payload.account.billing;
                draft.phones = payload.account.phones;
                draft.contactPhone = payload.account.contact_phone;
                draft.plan = payload.account.plan;
                draft.selectedAccountPhone =
                    payload.account?.phones?.find((phone) => phone.type && phone.type.toUpperCase() === "PRIMARY") ||
                    payload.account.phones[0]; // Need a type primary here, not just first phone
                draft.superAdmin = payload.superAdmin;
                draft.tags = payload.account.tags;
                draft.usages = payload.account.usages;
                draft.users = payload.account.users;
                draft.uuid = payload.account.uuid;
                draft.verified = payload.account.verified;
                return draft;
            case GET_ACCOUNT_ERROR:
                draft.error = true;
                draft.loading = false;
                console.log("Account Error ", payload);
                return draft;

            case SELECT_ACCOUNT_PHONE:
                draft.selectedAccountPhone = payload;
                return draft;
            case UPDATE_ACCOUNT_ERROR:
                draft.loading = false;
                return draft;
            case UPDATE_ACCOUNT_REQUEST:
                draft.loading = true;
                return draft;
            case UPDATE_ACCOUNT_SUCCESS:
                draft.loading = false;
                draft.apiKey = payload.api_key ? payload.api_key : draft.apiKey;
                draft.email = payload.contact_email ? payload.contact_email : draft.email;
                draft.optinBypass = payload.double_optin_bypass ? payload.double_optin_bypass : draft.optinBypass;
                draft.optinMessage = payload.optin_message ? payload.optin_message : draft.optinMessage;
                draft.optinMessageConfirmation = payload.optin_message_confirmation
                    ? payload.optin_message_confirmation
                    : draft.optinMessageConfirmation;
                draft.friendlyName = payload.friendly_name ? payload.friendly_name : draft.friendlyName;
                draft.id = payload.id ? payload.id : draft.id;
                draft.planId = payload.plan_id ? payload.plan_id : draft.planId;
                draft.verified = payload.verified ? payload.verified : draft.verified;
                return draft;

            // ACCOUNT INTEGRATIONS
            case GET_ACCOUNTINTEGRATIONS_ERROR:
                draft.error = true;
                draft.loading = false;
                draft.integrations = null;
                return draft;
            case GET_ACCOUNTINTEGRATIONS_REQUEST:
                draft.loading = true;
                draft.error = false;
                return draft;
            case GET_ACCOUNTINTEGRATIONS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.integrations = payload.accountIntegrations;
                return draft;
            case CREATE_ACCOUNTINTEGRATION_SUCCESS:
                draft.loading = false;
                draft.error = false;
                if (draft.integrations) {
                    draft.integrations.push(payload);
                } else {
                    draft.integrations = [payload];
                }
                return draft;

            case SYNC_ACCOUNTINTEGRATION_SUCCESS:
                if (draft.integrations !== null) {
                    draft.integrations.splice(
                        draft.integrations.findIndex((int) => int.id === payload.id),
                        1,
                        payload
                    );
                } else {
                    draft.integrations = [payload];
                }
                return draft;

            case REMOVE_ACCOUNTINTEGRATIONS_ERROR:
                draft.error = true;
                draft.loading = false;
                draft.integrations = null;
                return draft;
            case REMOVE_ACCOUNTINTEGRATIONS_REQUEST:
                draft.error = false;
                draft.loading = true;
                return draft;
            case REMOVE_ACCOUNTINTEGRATIONS_SUCCESS:
                draft.error = false;
                draft.loading = false;
                draft.integrations = draft.integrations?.filter((int) => int.id !== payload);
                return draft;

            // ACCOUNT INTEGRATION EVENTS & REMINDERS
            case CREATE_EVENT_SUCCESS:
                integrationIndex = draft.integrations?.findIndex(
                    (ai) => ai.id === payload.event.account_integration_id
                );
                if (integrationIndex) {
                    draft.integrations[integrationIndex]?.events.unshift(payload.event);
                }
                return draft;

            case RESERVE_PHONE_ERROR:
                draft.loading = false;
                draft.error = true;
                return draft;
            case RESERVE_PHONE_REQUEST:
                draft.loading = true;
                draft.error = false;
                return draft;
            case RESERVE_PHONE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.phones.push(payload);
                return draft;
            default:
                return draft;
        }
    });
};

export default accountReducer;
