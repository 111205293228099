import produce from "immer";
import {
    CREATE_FIELD_ERROR,
    CREATE_FIELD_REQUEST,
    CREATE_FIELD_SUCCESS,
    DELETE_FIELD_ERROR,
    DELETE_FIELD_REQUEST,
    DELETE_FIELD_SUCCESS,
    EDIT_FIELD_ERROR,
    EDIT_FIELD_REQUEST,
    EDIT_FIELD_SUCCESS,
    GET_FIELDS_ERROR,
    GET_FIELDS_REQUEST,
    GET_FIELDS_SUCCESS,
} from "../actions/types";

const initialState = {
    loading: false,
    list: [],
};

const tagsReducer = (state = initialState, action) => {
    const { payload, type } = action;

    return produce(state, (draft) => {
        switch (type) {
            case CREATE_FIELD_ERROR:
                draft.loading = false;
                return draft;
            case CREATE_FIELD_REQUEST:
                draft.loading = true;
                return draft;
            case CREATE_FIELD_SUCCESS:
                draft.loading = false;
                draft.list.unshift(payload);
                return draft;
            case DELETE_FIELD_ERROR:
                draft.loading = false;
                return draft;
            case DELETE_FIELD_REQUEST:
                draft.loading = true;
                return draft;
            case DELETE_FIELD_SUCCESS:
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1
                );
                return draft;
            case EDIT_FIELD_ERROR:
                draft.loading = false;
                return draft;
            case EDIT_FIELD_REQUEST:
                draft.loading = true;
                return draft;
            case EDIT_FIELD_SUCCESS:
                draft.loading = false;
                draft.list.splice(
                    draft.list.findIndex((li) => li.id === payload.id),
                    1,
                    payload
                );
                return draft;
            case GET_FIELDS_REQUEST:
                draft.loading = true;
                return draft;
            case GET_FIELDS_SUCCESS:
                draft.loading = false;
                draft.list = payload.map((field) => ({
                    id: field.id,
                    type: field.type,
                    key: field.key,
                    label: field.label,
                    value: field.default_value,
                }));
                return draft;
            case GET_FIELDS_ERROR:
                draft.loading = false;
                return [];

            default:
                return draft;
        }
    });
};

export default tagsReducer;
