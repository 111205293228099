export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// App
export const SET_NAV_HEIGHT = "SET_NAV_HEIGHT";
export const SET_DASH_VIEW_HEIGHT = "SET_DASH_VIEW_HEIGHT";

// Auth
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const VERIFY_RESEND_ERROR = "VERIFY_RESEND_ERROR";
export const VERIFY_RESEND_REQUEST = "VERIFY_RESEND_REQUEST";
export const VERIFY_RESEND_SUCCESS = "VERIFY_RESEND_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";
export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";

// Account
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";
export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNTINTEGRATIONS_ERROR = "GET_ACCOUNTINTEGRATIONS_ERROR";
export const GET_ACCOUNTINTEGRATIONS_REQUEST = "GET_ACCOUNTINTEGRATIONS_REQUEST";
export const GET_ACCOUNTINTEGRATIONS_SUCCESS = "GET_ACCOUNTINTEGRATIONS_SUCCESS";
export const CREATE_ACCOUNTINTEGRATION_SUCCESS = "CREATE_ACCOUNTINTEGRATION_SUCCESS";
export const REMOVE_ACCOUNTINTEGRATIONS_REQUEST = "REMOVE_ACCOUNTINTEGRATIONS_REQUEST";
export const REMOVE_ACCOUNTINTEGRATIONS_SUCCESS = "REMOVE_ACCOUNTINTEGRATIONS_SUCCESS";
export const REMOVE_ACCOUNTINTEGRATIONS_ERROR = "REMOVE_ACCOUNTINTEGRATIONS_ERROR";
export const SYNC_ACCOUNTINTEGRATION_SUCCESS = "SYNC_ACCOUNTINTEGRATION_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";
export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";

export const SELECT_ACCOUNT_PHONE = "SELECT_ACCOUNT_PHONE";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_SETUP = "VERIFY_ACCOUNT_SETUP";

// Billing
export const CREATE_BILLING_ERROR = "GET_ACCOUNT_ERROR";
export const CREATE_BILLING_REQUEST = "CREATE_BILLING_REQUEST";
export const CREATE_BILLING_SUCCESS = "CREATE_BILLING_SUCCESS";
export const GET_BILLING_ERROR = "GET_BILLING_ERROR";
export const GET_BILLING_REQUEST = "GET_BILLING_REQUEST";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";

// Broadcasts
export const CANCEL_BROADCAST_ERROR = "CANCEL_BROADCAST_ERROR";
export const CANCEL_BROADCAST_REQUEST = "CANCEL_BROADCAST_REQUEST";
export const CANCEL_BROADCAST_SUCCESS = "CANCEL_BROADCAST_SUCCESS";
export const CREATE_BROADCAST_ERROR = "CREATE_BROADCAST_ERROR";
export const CREATE_BROADCAST_REQUEST = "CREATE_BROADCAST_REQUEST";
export const CREATE_BROADCAST_SUCCESS = "CREATE_BROADCAST_SUCCESS";
export const GET_ALL_BROADCASTS_ERROR = "GET_ALL_BROADCASTS_ERROR";
export const GET_ALL_BROADCASTS_REQUEST = "GET_ALL_BROADCASTS_REQUEST";
export const GET_ALL_BROADCASTS_SUCCESS = "GET_ALL_BROADCASTS_SUCCESS";
export const GET_BROADCAST_ERROR = "GET_BROADCAST_ERROR";
export const GET_BROADCAST_REQUEST = "GET_BROADCAST_REQUEST";
export const GET_BROADCAST_SUCCESS = "GET_BROADCAST_SUCCESS";
export const SET_SELECTED_BROADCAST = "SET_SELECTED_BROADCAST";
export const UPDATE_BROADCAST_ERROR = "UPDATE_BROADCAST_ERROR";
export const UPDATE_BROADCAST_REQUEST = "UPDATE_BROADCAST_REQUEST";
export const UPDATE_BROADCAST_SUCCESS = "UPDATE_BROADCAST_SUCCESS";
export const UPDATE_BROADCAST_DETAILS = "UPDATE_BROADCAST_DETAILS";

// Contacts
export const CREATE_CONTACT_ERROR = "CREATE_CONTACT_ERROR";
export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACTS_IMPORT_ERROR = "CREATE_CONTACTS_IMPORT_ERROR";
export const CREATE_CONTACTS_IMPORT_REQUEST = "CREATE_CONTACTS_IMPORT_REQUEST";
export const CREATE_CONTACTS_IMPORT_SUCCESS = "CREATE_CONTACTS_IMPORT_SUCCESS";
export const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR";
export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const GET_CONTACT_ERROR = "GET_CONTACT_ERROR";
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACTS_ERROR = "GET_CONTACTS_ERROR";
export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_LIST_SIMPLE_ERROR = "GET_LIST_SIMPLE_ERROR";
export const GET_LIST_SIMPLE_REQUEST = "GET_LIST_SIMPLE_REQUEST";
export const GET_LIST_SIMPLE_SUCCESS = "GET_LIST_SIMPLE_SUCCESS";
export const OPTIN_CONTACT_ERROR = "OPTIN_CONTACT_ERROR";
export const OPTIN_CONTACT_REQUEST = "OPTIN_CONTACT_REQUEST";
export const OPTIN_CONTACT_SUCCESS = "OPTIN_CONTACT_SUCCESS";
export const OPTOUT_CONTACT_ERROR = "OPTOUT_CONTACT_ERROR";
export const OPTOUT_CONTACT_REQUEST = "OPTOUT_CONTACT_REQUEST";
export const OPTOUT_CONTACT_SUCCESS = "OPTOUT_CONTACT_SUCCESS";
export const UNSUBSCRIBE_LIST_CONTACT_ERROR = "UNSUBSCRIBE_LIST_CONTACT_ERROR";
export const UNSUBSCRIBE_LIST_CONTACT_REQUEST = "UNSUBSCRIBE_LIST_CONTACT_REQUEST";
export const UNSUBSCRIBE_LIST_CONTACT_SUCCESS = "UNSUBSCRIBE_LIST_CONTACT_SUCCESS";
export const UPDATE_CONTACT_ERROR = "UPDATE_CONTACT_ERROR";
export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPLOAD_CONTACTS_ERROR = "UPLOAD_CONTACTS_ERROR";
export const UPLOAD_CONTACTS_REQUEST = "UPLOAD_CONTACTS_REQUEST";
export const UPLOAD_CONTACTS_SUCCESS = "UPLOAD_CONTACTS_SUCCESS";

export const ARCHIVE_CONVERSATION_ERROR = "ARCHIVE_CONVERSATION_ERROR";
export const ARCHIVE_CONVERSATION_REQUEST = "ARCHIVE_CONVERSATION_REQUEST";
export const ARCHIVE_CONVERSATION_SUCCESS = "ARCHIVE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_ERROR = "CREATE_CONVERSATION_ERROR";
export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CLEAR_CURRENT_CONVERSATION = "CLEAR_CURRENT_CONVERSATION";
export const CREATE_DRAFT_CONVERSATION = "CREATE_DRAFT_CONVERSATION";
export const DELETE_DRAFT_CONVERSATION = "DELETE_DRAFT_CONVERSATION";
export const GET_CONVERSATION_ERROR = "GET_CONVERSATION_ERROR";
export const GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_ALL_CONVERSATION_LIST_ERROR = "GET_ALL_CONVERSATION_LIST_ERROR";
export const GET_ALL_CONVERSATION_LIST_REQUEST = "GET_ALL_CONVERSATION_LIST_REQUEST";
export const GET_ALL_CONVERSATION_LIST_SUCCESS = "GET_ALL_CONVERSATION_LIST_SUCCESS";
export const GET_CONVERSATION_LIST_ERROR = "GET_CONVERSATION_LIST_ERROR";
export const GET_CONVERSATION_LIST_REQUEST = "GET_CONVERSATION_LIST_REQUEST";
export const GET_CONVERSATION_LIST_SUCCESS = "GET_CONVERSATION_LIST_SUCCESS";
export const SELECT_DRAFT_CONTACT = "SELECT_DRAFT_CONTACT";
export const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION";
export const UPDATE_CONVERSATION_THREAD = "UPDATE_CONVERSATION_THREAD";
export const UPDATE_CONVERSATION_ERROR = "UPDATE_CONVERSATION_ERROR";
export const UPDATE_CONVERSATION_REQUEST = "UPDATE_CONVERSATION_REQUEST";
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS";
export const SET_STATUS_ID = "SET_STATUS_ID";

// CONTACT CUSTOM FIELDS
export const CREATE_FIELD_ERROR = "CREATE_FIELD_ERROR";
export const CREATE_FIELD_REQUEST = "CREATE_FIELD_REQUEST";
export const CREATE_FIELD_SUCCESS = "CREATE_FIELD_SUCCESS";
export const DELETE_FIELD_ERROR = "DELETE_FIELD_ERROR";
export const DELETE_FIELD_REQUEST = "DELETE_FIELD_REQUEST";
export const DELETE_FIELD_SUCCESS = "DELETE_FIELD_SUCCESS";
export const EDIT_FIELD_ERROR = "EDIT_FIELD_ERROR";
export const EDIT_FIELD_REQUEST = "EDIT_FIELD_REQUEST";
export const EDIT_FIELD_SUCCESS = "EDIT_FIELD_SUCCESS";
export const GET_FIELDS_ERROR = "GET_FIELDS_ERROR";
export const GET_FIELDS_REQUEST = "GET_FIELDS_REQUEST";
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS";

// Keywords
export const CREATE_KEYWORD_ERROR = "CREATE_KEYWORD_ERROR";
export const CREATE_KEYWORD_REQUEST = "CREATE_KEYWORD_REQUEST";
export const CREATE_KEYWORD_SUCCESS = "CREATE_KEYWORD_SUCCESS";
export const CREATE_KEYWORD_ACTION_ERROR = "CREATE_KEYWORD_ACTION_ERROR";
export const CREATE_KEYWORD_ACTION_REQUEST = "CREATE_KEYWORD_ACTION_REQUEST";
export const CREATE_KEYWORD_ACTION_SUCCESS = "CREATE_KEYWORD_ACTION_SUCCESS";
export const DELETE_KEYWORD_ERROR = "DELETE_KEYWORD_ERROR";
export const DELETE_KEYWORD_REQUEST = "DELETE_KEYWORD_REQUEST";
export const DELETE_KEYWORD_SUCCESS = "DELETE_KEYWORD_SUCCESS";
export const DELETE_KEYWORD_ACTION_ERROR = "DELETE_KEYWORD_ACTION_ERROR";
export const DELETE_KEYWORD_ACTION_REQUEST = "DELETE_KEYWORD_ACTION_REQUEST";
export const DELETE_KEYWORD_ACTION_SUCCESS = "DELETE_KEYWORD_ACTION_SUCCESS";
export const GET_KEYWORDS_ERROR = "GET_KEYWORDS_ERROR";
export const GET_KEYWORDS_REQUEST = "GET_KEYWORDS_REQUEST";
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";
export const SET_SELECTED_KEYWORD = "SET_SELECTED_KEYWORD";
export const UPDATE_KEYWORD_ERROR = "UPDATE_KEYWORD_ERROR";
export const UPDATE_KEYWORD_REQUEST = "UPDATE_KEYWORD_REQUEST";
export const UPDATE_KEYWORD_SUCCESS = "UPDATE_KEYWORD_SUCCESS";
export const UPDATE_KEYWORD_ACTION_ERROR = "UPDATE_KEYWORD_ACTION_ERROR";
export const UPDATE_KEYWORD_ACTION_REQUEST = "UPDATE_KEYWORD_ACTION_REQUEST";
export const UPDATE_KEYWORD_ACTION_SUCCESS = "UPDATE_KEYWORD_ACTION_SUCCESS";

// Integrations
export const CREATE_INTEGRATIONS_ERROR = "CREATE_INTEGRATIONS_ERROR";
export const CREATE_INTEGRATIONS_REQUEST = "CREATE_INTEGRATIONS_REQUEST";
export const CREATE_INTEGRATIONS_SUCCESS = "CREATE_INTEGRATIONS_SUCCESS";
export const GET_INTEGRATIONS_ERROR = "GET_INTEGRATIONS_ERROR";
export const GET_INTEGRATIONS_REQUEST = "GET_INTEGRATIONS_REQUEST";
export const GET_INTEGRATIONS_SUCCESS = "GET_INTEGRATIONS_SUCCESS";
export const DELETE_INTEGRATIONS_ERROR = "DELETE_INTEGRATIONS_ERROR";
export const DELETE_INTEGRATIONS_REQUEST = "DELETE_INTEGRATIONS_REQUEST";
export const DELETE_INTEGRATIONS_SUCCESS = "DELETE_INTEGRATIONS_SUCCESS";

// Lists
export const CREATE_LIST_ERROR = "CREATE_LIST_ERROR";
export const CREATE_LIST_REQUEST = "CREATE_LIST_REQUEST";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const UPDATE_LIST_ERROR = "UPDATE_LIST_ERROR";
export const UPDATE_LIST_REQUEST = "UPDATE_LIST_REQUEST";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";

// Messages
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

// Phones
export const GET_ACCOUNT_PHONES_ERROR = "GET_ACCOUNT_PHONES_ERROR";
export const GET_ACCOUNT_PHONES_REQUEST = "GET_ACCOUNT_PHONES_REQUEST";
export const GET_ACCOUNT_PHONES_SUCCESS = "GET_ACCOUNT_PHONES_SUCCESS";
export const RELEASE_PHONE_REQUEST = "RELEASE_PHONE_REQUEST";
export const RELEASE_PHONE_SUCCESS = "RELEASE_PHONE_SUCCESS";
export const RELEASE_PHONE_ERROR = "RELEASE_PHONE_ERROR";
export const RESERVE_PHONE_ERROR = "RESERVE_PHONE_ERROR";
export const RESERVE_PHONE_REQUEST = "RESERVE_PHONE_REQUEST";
export const RESERVE_PHONE_SUCCESS = "RESERVE_PHONE_SUCCESS";

// Segments
export const CREATE_SEGMENT_ERROR = "CREATE_SEGMENT_ERROR";
export const CREATE_SEGMENT_REQUEST = "CREATE_SEGMENT_REQUEST";
export const CREATE_SEGMENT_SUCCESS = "CREATE_SEGMENT_SUCCESS";
export const DELETE_SEGMENT_ERROR = "DELETE_SEGMENT_ERROR";
export const DELETE_SEGMENT_REQUEST = "DELETE_SEGMENT_REQUEST";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";
export const GET_SEGMENTS_ERROR = "GET_SEGMENTS_ERROR";
export const GET_SEGMENTS_REQUEST = "GET_SEGMENTS_REQUEST";
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS";
export const UPDATE_SEGMENT_ERROR = "UPDATE_SEGMENT_ERROR";
export const UPDATE_SEGMENT_REQUEST = "UPDATE_SEGMENT_REQUEST";
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS";

// Backstage
export const TOGGLE_BACKSTAGE = "TOGGLE_BACKSTAGE";
