import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import WebSocketProvider from "./WebSocket";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import setAuthHeader from "./utils/setAuthHeader";
import store from "./store";
import { logoutUser, setCurrentUser } from "./actions/auth";
import SnackbarAlert from "./components/layout/SnackbarAlert";
import PrivateRoute from "./components/routing/PrivateRoute";
import ColorModeContext from "./contexts/ColorModeContext";
import { createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import getDesignTokens from "./config/theme";
import { CssBaseline, useMediaQuery } from "@mui/material";
import ErrorBoundary from "./views/errors/DefaultErrorBoundary";
import PostRegister from "./components/auth/PostRegister";
import Loading from "./views/system/Loading";
import "./App.css";
import "./fonts/GeneralSans-Medium.ttf";
import "./fonts/GeneralSans-Medium.woff";
import "./fonts/GeneralSans-Medium.woff2";

const Admin = lazy(() => import("./layouts/Admin"));
const Register = lazy(() => import("./components/auth/Register"));
const Login = lazy(() => import("./components/auth/Login"));
const Recover = lazy(() => import("./components/auth/Recover"));
const Reset = lazy(() => import("./components/auth/Reset"));
const Verify = lazy(() => import("./components/auth/Verify"));
const Billing = lazy(() => import("./views/billing/Billing"));
const NotFound = lazy(() => import("./views/system/NotFound"));
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

if (localStorage.roezan) {
    try {
        // Set auth token header auth
        const token = localStorage.roezan;
        setAuthHeader(token);
        const decoded = jwt_decode(token);
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded));

        // Check for expired token
        const currentTime = Date.now() / 1000; // to get in milliseconds
        if (decoded.exp < currentTime) {
            // Logout user and Redirect to Login
            store.dispatch(logoutUser());
            window.location.href = "./login";
        }
    } catch (err) {
        console.log("Unable to decode: ", err.message);
        // Redirect to login
        window.location.href = "./login";
    }
}

const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [mode, setMode] = React.useState(prefersDarkMode ? "dark" : "light");
    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );
    const theme = React.useMemo(
        () =>
            //TODO move to theme config file
            responsiveFontSizes(createTheme(getDesignTokens(mode))),
        [mode]
    );

    return (
        <Provider store={store}>
            <Router>
                <WebSocketProvider>
                    <Elements stripe={stripePromise}>
                        <ColorModeContext.Provider value={colorMode}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="App">
                                            <SnackbarAlert />
                                            <ErrorBoundary>
                                                <Suspense fallback={<Loading />}>
                                                    <Switch>
                                                        <Route
                                                            exact
                                                            path={"/docs/integrations/zoom"}
                                                            render={() =>
                                                                (window.location =
                                                                    "https://help.roezan.com/help/zoom-webinar-sms-reminders")
                                                            }
                                                        />
                                                        <Route path={"/zoomverify"}>
                                                            <Redirect push to={"/zoomverify/zoomverify.html"} />
                                                        </Route>
                                                        <Route path={"/purchase"} component={Billing} />
                                                        <Route exact path={"/register"} component={Register} />
                                                        <Route exact path={"/login"} component={Login} />
                                                        <Route exact path={"/account-setup"} component={PostRegister} />
                                                        <Route path={"/recover"} component={Recover} />
                                                        <Route path={"/reset-password"} component={Reset} />
                                                        <PrivateRoute path={"/admin"} component={Admin} />
                                                        <PrivateRoute path={"/verify"} component={Verify} />
                                                        <Route path={"/"}>
                                                            <Redirect to="/admin" />
                                                        </Route>
                                                        <Route component={NotFound} />
                                                    </Switch>
                                                </Suspense>
                                            </ErrorBoundary>
                                        </div>
                                    </LocalizationProvider>
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </ColorModeContext.Provider>
                    </Elements>
                </WebSocketProvider>
            </Router>
        </Provider>
    );
};

export default App;
